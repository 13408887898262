<template>
    <div class="max-w-4xl mx-auto p-5">
        <Card class="p-4">
            <template #title>
                <h1 class="text-2xl font-bold text-gray-800">{{ policyData.title }}</h1>
                <p class="text-sm italic text-gray-500 mt-1">Updated: {{ policyData.lastUpdated }}</p>
            </template>

            <template #content>
                <div class="mt-4">
                    <!-- Introduction - can be string or paragraph with links -->
                    <p v-if="typeof policyData.introduction === 'string'" class="text-gray-700 mb-6">
                        {{ policyData.introduction }}
                    </p>
                    <p v-else-if="policyData.introduction.type === 'paragraph'" class="text-gray-700 mb-6">
                        <template v-for="(content, cIndex) in policyData.introduction.content" :key="cIndex">
                            <!-- Text content -->
                            <template v-if="typeof content === 'string'">{{ content }}</template>

                            <!-- Inline link -->
                            <a
                                v-else-if="content.type === 'link'"
                                :href="content.isEmail ? 'mailto:' + content.url : content.url"
                                class="text-blue-600 hover:text-blue-800 hover:underline"
                                :target="content.isEmail ? '_self' : '_blank'"
                                rel="noopener noreferrer"
                            >
                                {{ content.text }}
                            </a>
                        </template>
                    </p>

                    <div v-for="(section, index) in policyData.sections" :key="index" class="mb-6">
                        <h2
                            v-if="section.title"
                            class="text-xl font-semibold text-gray-800 pb-2 border-b border-gray-200 mb-3"
                        >
                            {{ section.title }}
                        </h2>
                        <div v-for="(paragraph, pIndex) in section.content" :key="pIndex" class="mb-3">
                            <!-- Regular paragraph text -->
                            <p v-if="typeof paragraph === 'string'" class="text-gray-700">{{ paragraph }}</p>

                            <!-- List items -->
                            <ul v-else-if="paragraph.type === 'list'" class="list-disc pl-6 text-gray-700">
                                <li v-for="(item, iIndex) in paragraph.items" :key="iIndex" class="mb-2">
                                    <!-- Regular text list item -->
                                    <template v-if="typeof item === 'string'">{{ item }}</template>

                                    <!-- List item with inline links -->
                                    <template v-else-if="item.type === 'paragraph'">
                                        <template v-for="(content, cIndex) in item.content" :key="cIndex">
                                            <!-- Text content -->
                                            <template v-if="typeof content === 'string'">{{ content }}</template>

                                            <!-- Inline link -->
                                            <a
                                                v-else-if="content.type === 'link'"
                                                :href="content.isEmail ? 'mailto:' + content.url : content.url"
                                                class="text-blue-600 hover:text-blue-800 hover:underline"
                                                :target="content.isEmail ? '_self' : '_blank'"
                                                rel="noopener noreferrer"
                                            >
                                                {{ content.text }}
                                            </a>
                                        </template>
                                    </template>

                                    <!-- Direct link in list item -->
                                    <template v-else-if="item.type === 'link'">
                                        <a
                                            :href="item.isEmail ? 'mailto:' + item.url : item.url"
                                            class="text-blue-600 hover:text-blue-800 hover:underline"
                                            :target="item.isEmail ? '_self' : '_blank'"
                                            rel="noopener noreferrer"
                                        >
                                            {{ item.text }}
                                        </a>
                                    </template>
                                </li>
                            </ul>

                            <!-- Standalone link -->
                            <p v-else-if="paragraph.type === 'link'" class="text-gray-700">
                                <a
                                    :href="paragraph.isEmail ? 'mailto:' + paragraph.url : paragraph.url"
                                    class="text-blue-600 hover:text-blue-800 hover:underline"
                                    :target="paragraph.isEmail ? '_self' : '_blank'"
                                    rel="noopener noreferrer"
                                >
                                    {{ paragraph.text }}
                                </a>
                            </p>

                            <!-- Paragraph with inline links -->
                            <p v-else-if="paragraph.type === 'paragraph'" class="text-gray-700">
                                <template v-for="(content, cIndex) in paragraph.content" :key="cIndex">
                                    <!-- Text content -->
                                    <template v-if="typeof content === 'string'">{{ content }}</template>

                                    <!-- Inline link -->
                                    <a
                                        v-else-if="content.type === 'link'"
                                        :href="content.isEmail ? 'mailto:' + content.url : content.url"
                                        class="text-blue-600 hover:text-blue-800 hover:underline"
                                        :target="content.isEmail ? '_self' : '_blank'"
                                        rel="noopener noreferrer"
                                    >
                                        {{ content.text }}
                                    </a>
                                </template>
                            </p>
                        </div>
                    </div>

                    <Divider />

                    <div class="mt-6">
                        <h2 class="text-xl font-semibold text-gray-800 mb-3">{{ policyData.contact.title }}</h2>
                        <div v-for="(detail, index) in policyData.contact.details" :key="index" class="text-gray-700">
                            <!-- Regular text -->
                            <p v-if="typeof detail === 'string'">{{ detail }}</p>

                            <!-- Standalone link -->
                            <p v-else-if="detail.type === 'link'">
                                <a
                                    :href="detail.isEmail ? 'mailto:' + detail.url : detail.url"
                                    class="text-blue-600 hover:text-blue-800 hover:underline"
                                    :target="detail.isEmail ? '_self' : '_blank'"
                                    rel="noopener noreferrer"
                                >
                                    {{ detail.text }}
                                </a>
                            </p>

                            <!-- Paragraph with inline links -->
                            <p v-else-if="detail.type === 'paragraph'">
                                <template v-for="(content, cIndex) in detail.content" :key="cIndex">
                                    <!-- Text content -->
                                    <template v-if="typeof content === 'string'">{{ content }}</template>

                                    <!-- Inline link -->
                                    <a
                                        v-else-if="content.type === 'link'"
                                        :href="content.isEmail ? 'mailto:' + content.url : content.url"
                                        class="text-blue-600 hover:text-blue-800 hover:underline"
                                        :target="content.isEmail ? '_self' : '_blank'"
                                        rel="noopener noreferrer"
                                    >
                                        {{ content.text }}
                                    </a>
                                </template>
                            </p>
                        </div>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup lang="ts">
import { privacyPolicyData } from './privacyPolicyData'

// Use the imported privacy policy data
const policyData = privacyPolicyData
</script>
