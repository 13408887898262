<template>
    <header class="h-14 bg-gray-100 flex items-center justify-between px-6 py-2 border-b border-[#030b12]/10">
        <div class="flex items-center gap-2">
            <FaIcon :icon="['fas', 'circle-question']" />
            <h1 class="text-surface-800 text-xl font-semibold leading-7">Help center</h1>
        </div>
        <Button
            label="Request support"
            text
            size="small"
            class="font-medium leading-tight rounded-full"
            @click="handleRequestSupport"
        />
    </header>
</template>

<script lang="ts" setup>
const handleRequestSupport = () => {
    console.log('Request support button clicked')
}
</script>

<style scoped></style>
