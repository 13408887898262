import { createRouter, createWebHistory, type Router } from 'vue-router'
// ignore-eslint
import HelpCenter from './components/HelpCenter.vue'

export const helpCenterViewRoutes = [
    {
        path: '/help-center',
        name: 'HelpCenterView',
        component: HelpCenter
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'HelpCenterView') {
    router = createRouter({
        history: createWebHistory(),
        routes: helpCenterViewRoutes
    })
}