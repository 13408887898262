<template>
    <div class="py-3.5 px-6 border-black-100 border-b-[1px]">{{ title }}</div>
    <div
        class="py-2 px-6 font-normal text-sm gap-2 h-12 flex flex-row w-full overflow-hidden"
        :class="{ 'bg-primary-500/[.12]': hasSelectedRows }"
        data-testid="filter-section"
    >
        <!-- LEFT -->
        <div class="header-left flex flex-nowrap flex-1 overflow-x-auto self-center whitespace-nowrap pr-4">
            <slot name="selectedRowActions"></slot>
            <div ref="headerLeftRef" class="flex flex-nowrap items-center relative">
                <slot name="filterSection"></slot>
                <div
                    ref="clearButtonRef"
                    class="clear-filters flex-shrink-0 flex items-center bg-surface-100"
                    :class="showClearFilterButton ? 'inline-flex' : 'hidden'"
                >
                    <TableFiltersClearButton @clear-all-filters="emit('clear-all-filters')" />
                </div>
            </div>
        </div>

        <!-- RIGHT -->
        <div ref="headerRightRef" class="header-right w-96 flex-shrink-0 flex items-center justify-end">
            <slot name="right"> </slot>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, watch, computed, onMounted, onUnmounted, nextTick, toRefs } from 'vue'
import TableFiltersClearButton from '@/components/dataTable/tableFilter/TableFiltersClearButton.vue'

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    hasSelectedRows: {
        type: Boolean,
        required: true
    },
    hasActiveFilters: {
        type: Boolean,
        required: true
    },
    hasClearAllFilters: {
        type: Boolean,
        required: false,
        default: true
    }
})

const { hasActiveFilters } = toRefs(props)

const emit = defineEmits(['clear-all-filters'])

const showClearFilterButton = computed(() => {
    return hasActiveFilters.value && props.hasClearAllFilters
})

const clearButtonRef = ref<HTMLElement | null>(null)
const headerRightRef = ref<HTMLElement | null>(null)
const headerLeftRef = ref<HTMLElement | null>(null)

const updateButtonPosition = () => {
    const clearButton = clearButtonRef.value
    const headerRight = headerRightRef.value
    const headerLeft = headerLeftRef.value

    if (!clearButton || !headerRight || !headerLeft) return

    const headerLeftRect = headerLeft.getBoundingClientRect()
    const headerRightRect = headerRight.getBoundingClientRect()

    if (headerLeftRect.right > headerRightRect.left) {
        clearButton.classList.add('sticky-button')
    } else {
        clearButton.classList.remove('sticky-button')
    }
}

const registerEvents = () => {
    const headerLeftElement = headerLeftRef.value
    if (headerLeftElement) {
        headerLeftElement.addEventListener('scroll', updateButtonPosition)
    }

    window.addEventListener('resize', updateButtonPosition)
}

const unregisterEvents = () => {
    const headerLeftElement = headerLeftRef.value
    if (headerLeftElement) {
        headerLeftElement.removeEventListener('scroll', updateButtonPosition)
    }

    window.removeEventListener('resize', updateButtonPosition)
}

onMounted(async () => {
    await nextTick()

    if (showClearFilterButton.value) {
        registerEvents()
        updateButtonPosition()
    }
})

watch(showClearFilterButton, async (newValue) => {
    await nextTick()

    if (newValue) {
        registerEvents()
        updateButtonPosition()
    } else {
        unregisterEvents()
        // Ensure button is reset when hidden
        const clearButton = clearButtonRef.value
        if (clearButton) {
            clearButton.classList.remove('sticky-button')
        }
    }
})

onUnmounted(() => {
    unregisterEvents()
})
</script>

<style lang="scss" scoped>
.header-left::-webkit-scrollbar {
    height: 0; /* Hide scrollbar in WebKit browsers */
}

.header-left {
    scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.sticky-button {
    position: sticky;
    padding-left: 1rem;
    right: -1rem;
    top: 0;
    bottom: 0;
    z-index: 10;
    width: auto;
}
</style>
