<template>
    <div
        class="h-[210px] min-w-[268px] max-w-[400px] w-full px-4 pb-4 pt-5 flex flex-col items-start cursor-pointer shadow-sm rounded-lg transition-transform duration-200 ease-in-out relative hover:-translate-y-1 hover:bg-primary-50 border border-border-black-50"
        @click="navigateToVideoDetail"
    >
        <div class="flex flex-col gap-2">
            <div :class="['absolute top-0 left-0 w-full h-2', cardTypeClass, 'rounded-t-lg']"></div>

            <div class="flex items-center mb-1">
                <FaIcon class="mr-1" :icon="typeIcon" :class="iconColor" />
                <span class="text-sm font-normal text-surface-600 leading-tight">{{ videoTypeLabel }}</span>
            </div>

            <div>
                <h3 class="text-base font-medium text-surface-900 leading-normal line-clamp-1">{{ title }}</h3>
                <p class="text-xs text-surface-600 leading-4 line-clamp-2">{{ description }}</p>
                <div class="flex flex-wrap gap-2 mt-2 max-h-6">
                    <ChannelPill v-if="appliedToAllChannels" :is-all-channels="true" channel="all" />
                    <ChannelPill v-for="channel in sortedChannels" v-else :key="channel" :channel="channel" />
                </div>
            </div>
        </div>
        <div class="flex justify-end w-full h-full items-end gap-2">
            <Button
                v-if="canEdit"
                class="w-12 h-12 !text-blue-600 !border-none p-4 box-border"
                rounded
                text
                @click.stop="handleEdit"
            >
                <FaIcon class="mx-0.5" icon="fas fa-pen" />
            </Button>
            <Button
                class="w-12 h-12 !bg-[#bfdfff] !text-blue-600 !border-none p-4 box-border"
                rounded
                @click.stop="playVideo"
            >
                <!-- Play icon -->
                <FaIcon class="mx-0.5" icon="fas fa-play" />
            </Button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import { useRouter } from 'vue-router'
import type { HelpCenterVideo } from '@/interfaces/HelpCenter'
import { HelpCenterType, HelpCenterChannel } from '@/interfaces/HelpCenter'
import ChannelPill from '@/components/ChannelPill.vue'

const router = useRouter()

const props = defineProps({
    video: {
        type: Object as PropType<HelpCenterVideo>,
        required: true
    },
    canEdit: {
        type: Boolean,
        required: false,
        default: false
    }
})

const emit = defineEmits(['edit'])

const title = computed(() => props.video.title)
const description = computed(() => props.video.description)

const sortedChannels = computed(() => {
    return [...(props.video.channels || [])].sort((a, b) => {
        return a.localeCompare(b)
    })
})

const appliedToAllChannels = computed(() => {
    const allChannels = Object.values(HelpCenterChannel)
    return allChannels.every((channel) => props.video.channels?.includes(channel))
})

const cardTypeClass = computed(() => {
    switch (props.video.type) {
        case HelpCenterType.TrainingVideo:
            return 'bg-[#6270FC]'
        case HelpCenterType.TuesdayTutorial:
            return 'bg-[#00AAED]'
        case HelpCenterType.Event:
            return 'bg-black'
        default:
            return 'bg-gray-500'
    }
})

const iconColor = computed(() => {
    switch (props.video.type) {
        case HelpCenterType.TrainingVideo:
            return 'text-[#6270FC]'
        case HelpCenterType.TuesdayTutorial:
            return 'text-[#00AAED]'
        case HelpCenterType.Event:
            return 'text-black'
        default:
            return 'text-gray-500'
    }
})

const videoTypeLabel = computed(() => {
    switch (props.video.type) {
        case HelpCenterType.TrainingVideo:
            return 'TRAINING'
        case HelpCenterType.TuesdayTutorial:
            return 'TUESDAY TUTORIAL'
        case HelpCenterType.Event:
            return 'EVENT'
        default:
            return 'VIDEO'
    }
})

const typeIcon = computed(() => {
    switch (props.video.type) {
        case HelpCenterType.TrainingVideo:
            return 'far fa-graduation-cap'
        case HelpCenterType.TuesdayTutorial:
            return 'far fa-screen-users'
        case HelpCenterType.Event:
            return 'far fa-presentation-screen'
        default:
            return 'far fa-file-video'
    }
})

function playVideo(event: Event) {
    event.stopPropagation()
    router.push({
        name: 'VideoDetails',
        params: { videoId: props.video.id },
        query: router.currentRoute.value.query
    })
}

function navigateToVideoDetail() {
    router.push({
        name: 'VideoDetails',
        params: { videoId: props.video.id },
        query: router.currentRoute.value.query
    })
}

const handleEdit = () => {
    emit('edit', props.video)
}
</script>
