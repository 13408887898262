import { createRouter, createWebHistory, type Router } from 'vue-router'
import UserManagementTable from './components/UserManagementTable.vue'

export const userManagementRoutes = [
    {
        path: '/company/:companyId/user-management',
        name: 'UserManagementTable',
        component: UserManagementTable,
        meta: { requiresAuth: true }
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'UserManagementPage') {
    router = createRouter({
        history: createWebHistory(),
        routes: userManagementRoutes
    })
}
