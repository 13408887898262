export interface LinkContent {
    type: 'link'
    url: string
    text: string
    isEmail?: boolean
}

export interface ParagraphContent {
    type: 'paragraph'
    content: (string | LinkContent)[]
}

export interface ListContent {
    type: 'list'
    items: (string | ParagraphContent | LinkContent)[]
}

export interface PolicySection {
    title: string
    content: (string | ListContent | LinkContent | ParagraphContent)[]
}

export interface PolicyData {
    title: string
    lastUpdated: string
    introduction: string | ParagraphContent
    sections: PolicySection[]
    contact: {
        title: string
        details: (string | LinkContent | ParagraphContent)[]
    }
}

export const privacyPolicyData: PolicyData = {
    title: 'PERFORMLINE SERVICES PRIVACY POLICY',
    lastUpdated: 'January 31, 2025',
    introduction: {
        type: 'paragraph',
        content: [
            'PerformLine Inc. values and respects your privacy. "PerformLine", "we", and "us" refers to PerformLine, Inc. This Privacy Policy (this "Policy", "Privacy Policy") describes the types of information (including Personal Information) we collect from our clients, our clients\' employees and vendors, and our clients\' partners (collectively, "Users") when they use our software, content and professional services (collectively, "Services"), how we use the information, with whom we share it, and the choices that can be made about our collection, use and disclosure of the information. We also describe the measures we take to protect the security of the information and how to contact us about our privacy practices. For information on the Personal Information that we collect from users of our corporate websites ("Sites"), we maintain a separate ',
            {
                type: 'link',
                url: 'https://performline.com/company/privacy-policy/',
                text: 'Privacy and Cookie Policy'
            },
            '. Please note that additional terms with respect to the collection, use and disclosure of information about European Union ("EU") Data Subjects is set forth below in the section entitled "Special Data Protections for EU Data Subjects."'
        ]
    },
    sections: [
        {
            title: '',
            content: [
                'Being transparent is important to us. Please read the following carefully to understand our practices regarding your personal data and how we will treat it. If you do not accept this Policy, you should not access or use our sites, use our Services or otherwise interact with any other aspect of our business.'
            ]
        },
        {
            title: 'Personal Information',
            content: [
                "Personal Information is information that identifies our Users, such as name, postal address, telephone number and email address. We may collect personal information in several ways, including when Users provide information to us or our clients in connection with their use of the Services. This includes when our clients access the Services and when our customers' clients contact our customers' call centers and participate in messaging sessions.",
                'We will collect Personal Information from Users if they voluntarily submit such information to us or our clients, including Personal Information that Users disclose in call center audio files, message transcripts, email, social media postings and other media. Users can always refuse to supply Personal Information, except that it may prevent them from using our Services. Please note that some jurisdictions, such as the EU, define Personal Information more broadly.'
            ]
        },
        {
            title: 'Non-Personal Information',
            content: [
                "Non-Personal Information is aggregated information, demographic information and any other information that does not reveal your specific identity. We and our third-party service providers may collect Non-Personal Information from you, including your browser name, the type of computer and technical information about Users' means of connection to our Services, such as the operating system and the Internet service providers utilized and other similar information. We and our third-party service providers may also aggregate Personal Information in a manner such that the end-product does not personally identify you or any other person, for example, by using Personal Information to calculate the number of visitors we have in a state."
            ]
        },
        {
            title: 'IP Addresses',
            content: [
                'Your IP Address is a number that is automatically assigned to the computer that you are using by your Internet Service Provider ("ISP"). This number is identified and logged automatically in our server log files whenever Users visit the Sites, along with the time(s) of such visit(s) and the page(s) that were visited. Collecting IP Addresses is standard practice on the Internet and is done automatically by many web sites.'
            ]
        },
        {
            title: 'How we use collected information',
            content: [
                'PerformLine may collect and use Personal Information through the Services for the following purposes:',
                {
                    type: 'list',
                    items: [
                        'For the purpose for which it was provided (e.g., to provide the Services, including to score the call center audio files, message transcripts, social media postings and other media)',
                        'To contact you.',
                        'To respond to your inquiries, questions and comments.',
                        'To inform you of products, programs, services and promotions that may be of interest to you.',
                        'For our internal business purposes, such as data analysis, audits, developing new products, enhancing our sites, improving our Services, etc.',
                        "To personalize Users' experiences.",
                        'To send to Users information about topics we think will be of interest to them.',
                        'To send periodic emails.'
                    ]
                },
                'We may use information in the aggregate to understand how our Users, as a group, use the Services to improve our Services.'
            ]
        },
        {
            title: 'How we protect your information',
            content: [
                'We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information stored through our Services.'
            ]
        },
        {
            title: 'How Is personal information disclosed',
            content: [
                'We may disclose Personal Information:',
                {
                    type: 'list',
                    items: [
                        {
                            type: 'paragraph',
                            content: [
                                'To our third-party service providers to enable them to provide their Services. For a list of third-party service providers go to our ',
                                {
                                    type: 'link',
                                    url: '/about/thirdparties/',
                                    text: 'third party vendor page'
                                },
                                '.'
                            ]
                        },
                        "To our clients and our client's employees on whose behalf we are providing the Services.",
                        'To an affiliate or other third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including without limitation, in connection with a bankruptcy or similar proceedings).',
                        "As we believe to be appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process, including where we are required to disclose User's information in response to a lawful request by public authorities, including to meet national security or law enforcement requirements; (c) to respond to requests from public and government authorities, including public and government authorities outside your country of residence; (d) to enforce our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, and/or that of our affiliates, you or others; and (g) to permit us to pursue available remedies or limit the damages that we may sustain."
                    ]
                }
            ]
        },
        {
            title: 'Other Important Notices Regarding Our Privacy Practices',
            content: [
                {
                    type: 'list',
                    items: [
                        'Third Party Sites: This Privacy Policy does not address, and we are not responsible for, the privacy, information or other practices of any third parties, including without limitation, any of our clients whose public-facing URLs our systems are monitoring, and any affiliates.',
                        {
                            type: 'paragraph',
                            content: [
                                'Accessing, Changing, or Suppressing Personal Information: If our clients would like to access, change, suppress or otherwise limit our use of the Personal Information about them and their customers that has been collected through our Services, they may contact us by sending an email to ',
                                {
                                    type: 'link',
                                    url: 'privacy@performline.com',
                                    text: 'privacy@performline.com',
                                    isEmail: true
                                },
                                " along with the pertinent information. If our clients' customers would like to access, change, suppress or otherwise limit our use of the Personal Information that has been collected through our Services on behalf of our clients, they should contact the client on whose behalf we are collecting such Personal Information and ask such client to contact us as set forth above. We will endeavor to comply with such requests as soon as reasonably practicable. For your protection, we will only implement opt-out requests with respect to the email address that you use to send us your request, and we may need to verify your identity before implementing your request. Please note that if you opt-out as described above, we will not be able to remove your Personal Information from the databases of our affiliates with which we have already shared your Personal Information (i.e., to which we have already provided your Personal Information as of the date that we implement your opt-out request). We may need to retain certain information for recordkeeping purposes, and there may also be residual information that will remain within our databases and other records, which will not be removed therefrom."
                            ]
                        },
                        'Retention Period: We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law. For example, if you are a client and have provided Personal Information, we will hold such information for so long as you remain a client. You may request that we change or delete your Personal Information by following the instructions above.'
                    ]
                }
            ]
        },
        {
            title: 'Special Data Protections for EU Data Subjects',
            content: [
                {
                    type: 'paragraph',
                    content: [
                        'As of May 25, 2018, the General Data Privacy Regulation ("GDPR") affords additional rights to EU data subjects. Those rights include the right to complain to EU Supervisory Authorities and the right to access, correct and delete certain personal data processed by PerformLine by contacting us by sending an email at ',
                        {
                            type: 'link',
                            url: 'privacy@performline.com',
                            text: 'privacy@performline.com',
                            isEmail: true
                        },
                        '. Moreover, with respect to EU data subjects, Personal Information includes pseudonymous data such as an IP address, a mobile advertising ID or a cookie ID. We process certain Personal Information provided by Users under contractual necessity. For example, we require the billing details of our customers located in the EU to process payment. We collect contact details from EU data subjects via our Services with consent in order to provide the Services. Where consent is our legal basis for processing, we endeavor to make it just as easy to withdraw consent as it is to provide consent. Where PerformLine receives from an EU data subject a request to cease processing of data via one of the choice mechanisms listed above, PerformLine will stop all data processing unless such processing is required by law. The retention period for data from EU data subjects will be based on the specific data collected and the purpose of processing such data.'
                    ]
                }
            ]
        },
        {
            title: 'Information required for data requests',
            content: [
                'In order to honor requests to access, change or otherwise limit the use of the data collected through the use of our Services, please provide the following:',
                {
                    type: 'list',
                    items: [
                        'Date/Time the Call/Messaging took place',
                        'Name of the Agent/Representative that handled the Call/Messaging',
                        '"Inquiry" ID or "Session" ID of the Call/Messaging - this is the unique identifier in our Customers\' system',
                        'PerformLine Page ID (this is the unique identifier in the PerformLine system)',
                        'CallerID (Phone Number) of the Customer'
                    ]
                }
            ]
        },
        {
            title: 'Changes to this Privacy Policy',
            content: [
                'PerformLine has the discretion to update this privacy policy at any time. When we do, we will post a notification through the Services and revise the updated date at the top of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the Personal Information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.'
            ]
        },
        {
            title: 'Your Acceptance of these Terms',
            content: [
                'By using this Service, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Services. Your continued use of the Services following the posting of changes to this policy will be deemed your acceptance of those changes.'
            ]
        }
    ],
    contact: {
        title: 'Contacting Us',
        details: [
            'If you have any questions about this Privacy Policy, please contact us at:',
            'PerformLine',
            '58 South Street',
            'Morristown, NJ 07960',
            {
                type: 'link',
                url: 'privacy@performline.com',
                text: 'privacy@performline.com',
                isEmail: true
            }
        ]
    }
}
