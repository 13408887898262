import httpClient from './httpClient'
import { handleApiError } from '@/utils/handleApiError'
import type { AxiosResponse, AxiosError } from 'axios'
import type {
    GetHelpCenterApiParams,
    GetHelpCenterVideosApiResponse,
    PostHelpCenterApiBody,
    PostHelpCenterApiResponse,
    PutHelpCenterApiBody,
    PutHelpCenterApiResponse,
    GetHelpCenterVideoApiResponse,
    DeleteHelpCenterVideoApiResponse,
    HelpCenterRequestApiBody,
    HelpCenterRequestApiResponse
} from './interfaces/helpCenter.types'

const getVideos = async (params?: GetHelpCenterApiParams): Promise<GetHelpCenterVideosApiResponse> => {
    try {
        const response: AxiosResponse<GetHelpCenterVideosApiResponse> = await httpClient.get('/helpcenter/videos', {
            params
        })
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const createVideo = async (body: PostHelpCenterApiBody): Promise<PostHelpCenterApiResponse> => {
    try {
        const response: AxiosResponse<PostHelpCenterApiResponse> = await httpClient.post('/helpcenter/videos', body)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const updateVideo = async (id: string, body: PutHelpCenterApiBody): Promise<PutHelpCenterApiResponse> => {
    try {
        const response: AxiosResponse<PutHelpCenterApiResponse> = await httpClient.put(`/helpcenter/videos/${id}`, body)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const getVideo = async (id: string): Promise<GetHelpCenterVideoApiResponse> => {
    try {
        const response: AxiosResponse<GetHelpCenterVideoApiResponse> = await httpClient.get(`/helpcenter/video/${id}`)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const deleteVideo = async (id: string): Promise<DeleteHelpCenterVideoApiResponse> => {
    try {
        const response: AxiosResponse<DeleteHelpCenterVideoApiResponse> = await httpClient.delete(
            `/helpcenter/videos/${id}`
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const submitRequest = async (body: HelpCenterRequestApiBody): Promise<HelpCenterRequestApiResponse> => {
    try {
        const response: AxiosResponse<HelpCenterRequestApiResponse> = await httpClient.post('/helpcenter/request', body)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

export default { getVideos, createVideo, updateVideo, getVideo, deleteVideo, submitRequest }
