<template>
    <div class="p-12 bg-cover bg-center" :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div class="inset-0 flex flex-col items-center justify-center">
            <div class="text-surface-800 text-4xl font-bold leading-10 mb-8">Help Center</div>
            <div class="flex items-center">
                <span class="relative mr-4">
                    <FaIcon
                        icon="far fa-search"
                        class="absolute top-2/4 -mt-2.5 left-3.5 text-xl text-surface-800 dark:text-surface-600"
                    />
                    <InputText
                        v-model="searchQuery"
                        placeholder="Search in video library"
                        class="pl-11 w-[360px] py-2.5 rounded-full"
                        @focus="showSuggestions = true"
                        @blur="handleBlur"
                        @keyup.enter="handleSearch"
                    />
                    <div
                        v-if="showSuggestions"
                        class="absolute w-full mt-1 bg-white rounded-2xl shadow-lg border border-surface-200 max-h-[320px] overflow-y-auto z-50"
                        :class="{ 'border-none': searchQuery.length === 0 }"
                    >
                        <template v-if="searchQuery">
                            <div
                                class="p-3 hover:bg-surface-100 cursor-pointer"
                                @mousedown.prevent="selectSuggestion(searchQuery)"
                            >
                                <div class="flex items-center">
                                    <FaIcon icon="far fa-search" class="mr-2 text-surface-600" />
                                    Search for "{{ searchQuery }}"
                                </div>
                            </div>
                        </template>
                        <template v-if="filteredHistory.length > 0">
                            <!-- <div class="p-2 text-xs text-surface-600 bg-surface-50">Recent Searches</div> -->
                            <div
                                v-for="history in filteredHistory"
                                :key="`history-${history}`"
                                class="p-3 hover:bg-surface-100 cursor-pointer group"
                                @mousedown.prevent="selectSuggestion(history)"
                            >
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center">
                                        <FaIcon icon="far fa-clock" class="mr-2 text-surface-600" />
                                        {{ history }}
                                    </div>
                                    <Button
                                        icon="far fa-xmark"
                                        text
                                        size="small"
                                        class="opacity-0 group-hover:opacity-100 transition-opacity w-4 h-4"
                                        @mousedown.stop.prevent="removeHistory(history)"
                                    />
                                </div>
                            </div>
                        </template>
                        <div v-if="isLoadingSuggestions" class="p-3 text-center">Loading...</div>
                        <template v-else-if="suggestions.length > 0">
                            <!-- <div v-if="filteredHistory.length > 0" class="p-2 text-xs text-surface-600 bg-surface-50">
                                    Suggestions
                                </div> -->
                            <div
                                v-for="suggestion in suggestions"
                                :key="suggestion.id"
                                class="hover:bg-surface-100 cursor-pointer px-4 py-3"
                                @mousedown.prevent="selectSuggestion(suggestion.title)"
                            >
                                <div class="flex items-center">
                                    <FaIcon
                                        :icon="['fas', typeIcon(suggestion)]"
                                        :class="typeColor(suggestion)"
                                        class="mr-4"
                                        size="sm"
                                    />
                                    {{ suggestion.title }}
                                </div>
                            </div>
                        </template>
                    </div>
                </span>
                <Button label="Search" rounded class="font-semibold" @click="handleSearch" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { useHelpCenterStore } from '@/stores/helpCenter'
import backgroundImage from '@/assets/images/help-center-hero-img.png'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { HelpCenterType, type HelpCenterVideo } from '@/interfaces/HelpCenter'
import { storeToRefs } from 'pinia'

const helpCenterStore = useHelpCenterStore()
const { filteredSuggestions } = storeToRefs(helpCenterStore)
const searchQuery = ref('')
const showSuggestions = ref(false)
const suggestions = ref<HelpCenterVideo[]>([])
const isLoadingSuggestions = ref(false)
import { typeOptions } from '../constants'

const router = useRouter()
const toast = useToast()

const filteredHistory = computed(() => {
    if (!searchQuery.value) return filteredSuggestions.value
    return filteredSuggestions.value.filter((history) =>
        history.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
})

const debouncedSearch = useDebounceFn(
    async () => {
        if (!searchQuery.value) {
            suggestions.value = []
            return
        }

        isLoadingSuggestions.value = true
        try {
            const videos: HelpCenterVideo[] | undefined = await helpCenterStore.fetchVideos({
                storeResults: false,
                filters: {
                    search: searchQuery.value,
                    size: 5,
                    page: 1
                }
            })
            suggestions.value = videos || []
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to fetch suggestions',
                life: 3000
            })
        } finally {
            isLoadingSuggestions.value = false
        }
    },
    400,
    { maxWait: 3000 }
)

watch(searchQuery, () => {
    if (searchQuery.value) {
        debouncedSearch()
    } else {
        suggestions.value = []
    }
})

const typeIcon = (video: HelpCenterVideo) => {
    return typeOptions.find((type) => type.value === video.type)?.icon || 'fa-video'
}

const typeColor = (video: HelpCenterVideo) => {
    switch (video.type) {
        case HelpCenterType.TrainingVideo:
            return 'text-[#6270FC]'
        case HelpCenterType.TuesdayTutorial:
            return 'text-[#00AAED]'
        case HelpCenterType.Event:
            return 'text-black'
        default:
            return 'text-gray-500'
    }
}

const handleSearch = () => {
    router.push({
        name: 'VideoLibrary',
        query: { search: searchQuery.value }
    })
}

const selectSuggestion = (suggestion: string) => {
    searchQuery.value = suggestion
    showSuggestions.value = false
    helpCenterStore.storeSearchInHistory(suggestion)
    handleSearch()
}

const handleBlur = () => {
    setTimeout(() => {
        showSuggestions.value = false
    }, 200)
}

const removeHistory = (search: string) => {
    helpCenterStore.removeSearchFromHistory(search)
}
</script>

<style scoped>
.p-button-primary {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rounded-full {
    border-radius: 9999px;
}
</style>
