interface SupportTemplate {
    subject: string
    description: string
}

export const auditReportTemplate: SupportTemplate = {
    subject: 'Audit Report',
    description: `<p>Audit Report Request:</p><ul><li>Reports available:</li><li class="ql-indent-1"><strong>Asset Metadata</strong> - Provides proof to auditors that clients actively monitor assets for compliance.</li><li class="ql-indent-1"><strong>Proof of Action </strong>- Three reports are available to show auditors how users interact with assets:</li></ul><ol><li class="ql-indent-2"><strong>Review History </strong>- Displays the date and user responsible for marking an asset as reviewed, based on the asset’s creation date. (Channels: Call, Email, Message, Social, Web)</li><li class="ql-indent-2"><strong>Remediation History Report </strong>- Displays historical remediation actions based on the asset’s creation date. (Channels: Call, Email, Message, Social, Web)</li><li class="ql-indent-2"><strong>Cases History Report </strong>- Shows all activity for Cases created within a given time period. (Channel: Document)</li></ol><p>----------------------------------------------------------------------------------------------</p><p>Leaving this section empty will assume all reports are needed and will default to 1 year.</p><br/><ul><li>Which reports are being requested?</li><li>Which channel(s) do you need this report for?</li><li class="ql-indent-1">Which brand(s) should be included in this report? (Your Brands are listed under each Channel at the top of the page.)</li><li class="ql-indent-1">Which campaigns(s) should be included in this report?</li><li>What is the requested date range, including the start and end? (These dates are used to pull the assets created between this timeframe.)</li></ul><p><br></p><p><em>Note: </em>Data can be requested up to one year from the current date, in line with PerformLine’s standard data retention policy. However, clients may request data older than one year if their internal retention policy permits.</p>`
}

export const supportTemplates: Record<string, SupportTemplate> = {
    audit: auditReportTemplate
}
