import { defineStore } from 'pinia'
import { ref } from 'vue'
import tagsApi from '@/api/tags.api'
import {
    type TagSummary,
    type ContentTag,
    type ContentTagsResp,
    type TagFilterApiParams,
    type PutContentTagResp,
} from '@/interfaces/Tag'
import type { PageInfo } from '@/interfaces/PageInfo'
import type { FilterAverageScorePayload  } from '@/interfaces/Table'

export const useTagsStore = defineStore('tags', () => {
    const tagSummaries = ref<TagSummary[]>([])
    const pageInfo = ref<PageInfo>({ totalCount: 0, hasMore: false, currentPage: 1, size: 25, totalPages: 1 })
    const loading = ref<boolean>(true)
    const avgScoreFilter = ref<FilterAverageScorePayload>({ minScore: null, maxScore: null, type: 'avgScore', isCustom: false })

    async function removeTag(brandId: string, tagId: number) {
        loading.value = true

        try {
            await tagsApi.deleteTag(brandId, tagId)
            tagSummaries.value = tagSummaries.value.filter((tagSummary) => tagSummary.tag.id !== tagId)
        } catch (err: unknown) {
            console.error(err)
            throw new Error(`Failed to delete tag with id ${tagId}`)
        } finally {
            loading.value = false
        }
    }

    async function editTag(brandId: string, updatedTag: ContentTag) {
        loading.value = true

        const index = tagSummaries.value.findIndex((tagSummary) => tagSummary.tag.id === updatedTag.id)
        if (index !== -1) {
            try {
                const editResponse: PutContentTagResp = await tagsApi.updateTag(brandId, updatedTag.id, {
                    name: updatedTag.name
                })
                tagSummaries.value[index].tag = editResponse.data
            } catch (err: any) {
                console.error(err)
                throw new Error(`Failed to update tag with id ${updatedTag.id}`)
            } finally {
                loading.value = false
            }
        }
    }

    const fetchTags = async (brandId: string, params?: TagFilterApiParams): Promise<ContentTagsResp> => {
        loading.value = true
        // Change for Desc. This will likely be extracted into a reusable util
        // if (params?.sortDirection === -1) {
        //     params.orderBy = '-' + params.orderBy
        // }

        try {
            const response: ContentTagsResp = await tagsApi.getTags(brandId, params)
            tagSummaries.value = response.data
            pageInfo.value = response.pageInfo
            return response
        } catch (err: any) {
            console.error(err)
            throw new Error('Failed to fetch tags')
        } finally {
            loading.value = false
        }
    }

    const createTag = async (brandId: string, tag: Partial<ContentTag>) => {
        loading.value = true

        try {
            await tagsApi.createTag(brandId, { name: tag.name })
        } catch (err: any) {
            console.error(err)
            throw new Error(err.message)
        } finally {
            loading.value = false
        }
    }

    const clearScoreFilter = (): void => {
        avgScoreFilter.value = { minScore: null, maxScore: null, type: 'avgScore', isCustom: avgScoreFilter.value.isCustom }
    }

    const clearAllFilters = (): void => {
        avgScoreFilter.value = { minScore: null, maxScore: null, type: 'avgScore', isCustom: avgScoreFilter.value.isCustom }
    }

    return { avgScoreFilter, loading, tagSummaries, removeTag, editTag, fetchTags, pageInfo, createTag, clearScoreFilter, clearAllFilters }
})
