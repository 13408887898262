<template>
    <svg width="227" height="228" viewBox="0 0 227 228" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g style="mix-blend-mode: multiply">
            <path
                d="M113.954 175.036C149.057 175.036 177.514 173.003 177.514 170.496C177.514 167.989 149.057 165.956 113.954 165.956C78.8508 165.956 50.394 167.989 50.394 170.496C50.394 173.003 78.8508 175.036 113.954 175.036Z"
                fill="#B7DDE1"
            />
        </g>
        <path
            d="M115.634 178.849C65.921 178.849 24.8794 176.307 24.8794 170.405C24.8794 164.503 65.9664 161.915 115.634 161.915C163.44 161.915 206.389 164.457 206.389 170.405C206.389 176.352 163.44 178.849 115.634 178.849ZM115.634 166.773C77.271 166.773 56.7502 168.18 56.7502 170.405C56.7502 172.629 76.9986 174.037 115.634 174.037C154.269 174.037 174.518 172.584 174.518 170.405C174.518 168.18 154.269 166.773 115.634 166.773Z"
            fill="#8DD5D8"
        />
        <path
            d="M98.2912 173.81C99.0176 171.54 102.786 165.729 110.186 155.65C110.867 154.742 111.366 154.061 111.684 153.653C113.455 151.383 114.817 150.066 118.721 145.027C120.991 142.121 123.534 138.807 124.85 136.945C133.885 123.961 138.334 117.287 136.473 110.341C134.248 102.078 124.578 97.8106 118.721 96.5848C110.731 94.905 101.061 97.175 100.153 100.852C99.9256 101.67 99.8802 103.576 101.015 104.575C101.605 105.12 102.65 105.438 103.331 105.075C104.42 104.484 104.102 102.305 104.329 102.305C104.556 102.305 105.646 105.165 104.239 106.936C102.74 108.843 99.4716 107.98 98.6544 107.753C96.4298 107.163 93.842 105.392 93.2064 103.032C92.026 98.7186 97.5648 94.0878 99.4262 92.4988C107.644 85.6434 122.671 81.9206 135.882 88.322C148.231 94.3148 152.68 106.119 153.952 109.887C157.901 122.009 154.315 132.769 152.09 139.17C144.554 160.78 129.753 164.458 131.206 177.124"
            fill="#242D3C"
        />
        <path
            d="M120.9 174.854C123.988 156.921 141.013 155.423 146.188 134.63C147.187 130.544 148.685 122.054 145.916 112.565C145.235 110.204 144.236 106.981 141.694 103.531C136.791 96.8568 129.844 94.496 126.53 93.4064C113.637 89.1842 103.966 93.4064 103.649 92.1352C103.331 90.7732 116.996 82.7374 131.115 87.8222C131.252 87.8222 131.297 87.913 131.388 87.913C133.885 88.8664 147.732 94.496 153.044 109.75C159.082 127.184 154.088 145.571 146.824 152.835C146.007 153.652 138.516 162.505 134.838 168.543C131.933 173.265 132.25 174.672 132.296 177.896"
            fill="#242D3C"
        />
        <path
            d="M120.083 173.992C119.992 169.588 121.899 165.956 124.351 165.911C126.802 165.865 128.891 169.361 128.982 173.81"
            fill="white"
        />
        <path
            d="M120.083 173.992C119.992 169.588 121.899 165.956 124.351 165.911C126.802 165.865 128.891 169.361 128.982 173.81"
            stroke="#242D3C"
            stroke-width="2.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M131.706 177.714C129.935 177.851 128.936 174.718 129.436 171.722C129.935 168.725 131.842 166.274 133.522 166.818C135.701 167.499 135.428 169.361 134.884 172.403C134.384 175.399 134.657 177.533 131.66 177.714H131.706Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M120.855 175.853C120.764 171.086 119.674 167.953 124.214 166.728C126.893 166.001 129.345 170.45 129.435 175.217"
            fill="#242D3C"
        />
        <path
            d="M120.855 175.853C120.764 171.086 119.674 167.953 124.214 166.728C126.893 166.001 129.345 170.45 129.435 175.217"
            stroke="white"
            stroke-width="2.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M122.852 176.67C122.761 172.448 121.99 169.678 125.168 168.634C127.029 167.998 128.754 171.948 128.89 176.17"
            fill="white"
        />
        <path
            d="M122.852 176.67C122.761 172.448 121.99 169.678 125.168 168.634C127.029 167.998 128.754 171.948 128.89 176.17"
            stroke="#242D3C"
            stroke-width="2.1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M123.669 176.807C123.624 173.81 123.034 171.813 125.349 171.086C126.711 170.632 127.937 173.447 127.982 176.444"
            fill="#242D3C"
        />
        <path
            d="M123.669 176.807C123.624 173.81 123.034 171.813 125.349 171.086C126.711 170.632 127.937 173.447 127.982 176.444"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M132.477 178.441C130.48 178.305 129.844 174.718 130.843 171.495C131.842 168.226 134.293 165.775 136.109 166.637C138.425 167.727 137.834 169.77 136.836 173.039C135.837 176.307 135.882 178.668 132.477 178.487V178.441Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M132.25 176.943C130.843 176.943 130.525 173.856 131.433 170.995C132.296 168.09 134.202 165.865 135.428 166.501C137.017 167.318 136.518 169.134 135.655 171.994C134.793 174.9 134.656 176.943 132.25 176.943Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M132.704 175.218C131.706 175.218 131.479 173.039 132.114 170.95C132.75 168.907 134.112 167.318 134.974 167.772C136.109 168.363 135.746 169.634 135.111 171.677C134.475 173.72 134.43 175.218 132.704 175.218Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M144.054 154.061C145.825 154.697 145.28 158.238 143.373 161.007C141.466 163.777 138.515 165.366 137.108 164.095C135.337 162.46 136.518 160.735 138.425 157.966C140.331 155.196 141.058 152.972 144.009 154.107L144.054 154.061Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M144.054 156.558C145.507 157.058 145.235 159.918 143.782 162.143C142.329 164.413 139.968 165.729 138.788 164.685C137.29 163.368 138.152 161.961 139.605 159.736C141.058 157.466 141.557 155.696 144.054 156.558Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M143.691 155.696C145.235 156.468 144.554 159.6 142.601 161.87C140.649 164.14 137.834 165.275 136.699 163.959C135.201 162.279 136.382 160.871 138.288 158.556C140.241 156.286 141.012 154.334 143.646 155.65L143.691 155.696Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M143.328 156.967C144.463 157.467 143.646 160.054 141.921 162.052C140.195 164.05 137.925 165.23 137.154 164.277C136.155 163.051 137.154 161.78 138.879 159.782C140.604 157.784 141.376 156.15 143.328 156.967Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M142.42 158.193C143.237 158.556 142.647 160.372 141.421 161.825C140.195 163.277 138.561 164.095 138.016 163.414C137.29 162.551 138.016 161.643 139.242 160.19C140.468 158.737 141.012 157.602 142.42 158.193Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M128.709 161.961C127.166 160.916 128.482 157.602 130.979 155.378C133.476 153.153 136.745 152.29 137.789 153.834C139.106 155.832 137.608 157.239 135.065 159.509C132.568 161.734 131.343 163.731 128.709 161.961Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M129.3 159.509C127.983 158.647 128.936 155.968 130.843 154.107C132.795 152.245 135.383 151.519 136.291 152.79C137.426 154.425 136.291 155.56 134.339 157.421C132.387 159.282 131.524 160.917 129.3 159.464V159.509Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M129.89 161.462C128.346 160.645 129.209 157.557 131.206 155.333C133.204 153.108 136.064 152.109 137.199 153.471C138.607 155.197 137.381 156.604 135.383 158.783C133.386 161.008 132.478 162.869 129.89 161.462Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M130.072 159.873C129.073 159.146 130.48 156.831 132.614 155.242C134.748 153.698 137.245 153.108 137.79 154.198C138.471 155.605 137.199 156.604 135.066 158.147C132.932 159.691 131.797 161.098 130.072 159.827V159.873Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M131.116 159.963C130.299 159.6 130.98 157.784 132.251 156.376C133.522 154.969 135.202 154.243 135.747 154.924C136.291 155.605 135.656 156.694 134.385 158.102C133.113 159.509 132.523 160.644 131.161 159.963H131.116Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M153.906 142.121C155.541 142.711 155.041 145.934 153.316 148.477C151.545 151.019 148.821 152.472 147.596 151.292C146.007 149.793 147.051 148.204 148.776 145.662C150.547 143.12 151.182 141.077 153.952 142.121H153.906Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M153.906 144.436C155.268 144.89 154.996 147.523 153.679 149.611C152.363 151.7 150.183 152.88 149.094 151.972C147.686 150.746 148.504 149.475 149.82 147.387C151.137 145.298 151.591 143.664 153.906 144.481V144.436Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M153.588 143.619C155.041 144.345 154.36 147.205 152.589 149.294C150.819 151.382 148.231 152.426 147.141 151.246C145.779 149.702 146.869 148.386 148.64 146.297C150.41 144.209 151.137 142.438 153.588 143.619Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M153.271 144.845C154.315 145.299 153.543 147.66 151.999 149.521C150.41 151.383 148.322 152.427 147.596 151.564C146.688 150.429 147.596 149.294 149.185 147.433C150.774 145.571 151.5 144.073 153.271 144.845Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M152.408 145.935C153.18 146.252 152.635 147.932 151.5 149.294C150.365 150.656 148.867 151.383 148.367 150.747C147.868 150.111 148.367 149.113 149.502 147.796C150.637 146.479 151.137 145.39 152.408 145.935Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M139.832 149.385C138.425 148.431 139.605 145.39 141.921 143.347C144.236 141.304 147.233 140.486 148.186 141.939C149.412 143.801 148.004 145.072 145.689 147.16C143.374 149.249 142.284 151.065 139.832 149.43V149.385Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M140.332 147.115C139.106 146.343 139.968 143.846 141.784 142.166C143.555 140.487 145.961 139.806 146.824 140.986C147.868 142.484 146.824 143.574 145.008 145.254C143.237 146.933 142.42 148.477 140.332 147.16V147.115Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M140.922 148.931C139.515 148.159 140.286 145.344 142.148 143.301C144.009 141.258 146.642 140.35 147.641 141.576C148.958 143.165 147.823 144.436 145.961 146.479C144.1 148.522 143.283 150.247 140.922 148.931Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M141.058 147.479C140.15 146.798 141.421 144.664 143.419 143.211C145.371 141.758 147.687 141.213 148.186 142.258C148.822 143.574 147.641 144.482 145.689 145.89C143.737 147.342 142.693 148.614 141.104 147.433L141.058 147.479Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M142.012 147.569C141.285 147.206 141.876 145.526 143.056 144.255C144.236 142.984 145.78 142.258 146.234 142.939C146.87 143.756 146.143 144.573 145.008 145.844C143.828 147.115 143.283 148.16 142.012 147.569Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M154.905 128.955C156.267 128.864 156.993 131.315 156.585 133.722C156.176 136.128 154.723 138.08 153.407 137.626C151.772 137.081 151.999 135.583 152.408 133.222C152.817 130.816 152.59 129.136 154.905 129V128.955Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M155.677 130.589C156.812 130.498 157.493 132.405 157.221 134.357C156.948 136.264 155.813 137.853 154.724 137.535C153.316 137.127 153.498 135.946 153.725 134.039C153.952 132.133 153.725 130.771 155.677 130.589Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M155.177 130.135C156.449 130.135 156.903 132.45 156.358 134.539C155.768 136.627 154.269 138.216 153.134 137.717C151.636 137.081 151.999 135.764 152.544 133.676C153.089 131.588 153.044 130.089 155.177 130.135Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M155.359 131.089C156.267 131.089 156.494 132.996 155.995 134.857C155.495 136.718 154.315 138.171 153.543 137.763C152.499 137.263 152.817 136.128 153.316 134.267C153.815 132.405 153.861 131.134 155.359 131.089Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M155.087 132.178C155.722 132.178 155.904 133.54 155.541 134.856C155.177 136.173 154.36 137.217 153.77 136.945C153.044 136.582 153.225 135.764 153.588 134.448C153.952 133.131 153.952 132.178 155.041 132.178H155.087Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M147.232 138.761C145.916 138.534 145.78 135.992 146.733 133.767C147.686 131.543 149.593 129.999 150.728 130.725C152.181 131.633 151.636 133.041 150.683 135.265C149.729 137.49 149.548 139.17 147.232 138.807V138.761Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M146.869 136.991C145.734 136.855 145.553 134.766 146.279 132.996C147.006 131.18 148.504 129.908 149.457 130.499C150.728 131.225 150.274 132.315 149.593 134.131C148.867 135.947 148.776 137.263 146.869 137.036V136.991Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M147.868 138.081C146.597 137.99 146.234 135.72 146.869 133.632C147.505 131.589 149.094 130.045 150.229 130.59C151.682 131.271 151.319 132.587 150.638 134.63C149.957 136.673 150.002 138.217 147.868 138.035V138.081Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M147.46 136.991C146.597 136.809 146.824 134.857 147.732 133.177C148.64 131.498 150.138 130.363 150.819 130.907C151.682 131.634 151.137 132.678 150.229 134.358C149.321 136.038 148.958 137.309 147.46 136.991Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M148.231 136.718C147.596 136.718 147.459 135.311 147.868 134.039C148.277 132.723 149.139 131.724 149.729 132.042C150.456 132.405 150.229 133.222 149.82 134.539C149.412 135.855 149.366 136.763 148.277 136.763L148.231 136.718Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M155.359 117.922C156.721 117.832 157.447 120.283 157.039 122.689C156.63 125.096 155.177 127.048 153.861 126.594C152.544 126.14 152.453 124.551 152.862 122.19C153.27 119.784 153.043 118.104 155.359 117.968V117.922Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M156.13 119.602C157.265 119.511 157.946 121.418 157.674 123.37C157.401 125.277 156.266 126.866 155.177 126.548C154.087 126.23 153.951 124.959 154.178 123.052C154.45 121.145 154.178 119.783 156.13 119.602Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M155.631 119.103C156.902 119.103 157.356 121.418 156.811 123.506C156.221 125.595 154.723 127.184 153.588 126.684C152.453 126.185 152.453 124.732 152.998 122.644C153.588 120.555 153.497 119.057 155.631 119.103Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M155.813 120.057C156.721 120.057 156.948 121.963 156.448 123.825C155.949 125.686 154.768 127.139 153.997 126.73C152.952 126.231 153.27 125.096 153.77 123.235C154.269 121.373 154.314 120.102 155.813 120.057Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M155.54 121.146C156.176 121.146 156.357 122.508 155.994 123.824C155.631 125.141 154.814 126.185 154.224 125.913C153.497 125.549 153.679 124.732 154.042 123.416C154.405 122.099 154.405 121.146 155.495 121.146H155.54Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M147.686 127.774C146.369 127.547 146.233 125.004 147.187 122.78C148.14 120.555 150.047 119.012 151.182 119.738C152.635 120.646 152.09 122.054 151.136 124.278C150.183 126.503 150.001 128.182 147.686 127.819V127.774Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M147.323 126.004C146.188 125.867 146.006 123.779 146.733 122.008C147.459 120.192 148.957 118.921 149.911 119.511C151.182 120.238 150.728 121.327 150.047 123.143C149.321 124.959 149.23 126.276 147.323 126.049V126.004Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M148.322 127.094C147.051 127.003 146.687 124.733 147.323 122.644C147.959 120.601 149.548 119.058 150.683 119.603C152.135 120.284 151.772 121.6 151.091 123.643C150.41 125.686 150.456 127.23 148.322 127.048V127.094Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M147.959 126.004C147.096 125.822 147.323 123.87 148.231 122.19C149.139 120.51 150.637 119.375 151.318 119.92C152.181 120.646 151.636 121.691 150.728 123.37C149.82 125.05 149.457 126.321 147.959 126.004Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M148.685 125.731C148.049 125.731 147.913 124.323 148.322 123.052C148.73 121.736 149.593 120.737 150.183 121.055C150.909 121.418 150.682 122.235 150.274 123.552C149.865 124.868 149.82 125.776 148.73 125.776L148.685 125.731Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M151.137 105.938C152.272 105.211 154.088 107.027 154.814 109.343C155.586 111.658 155.177 114.064 153.861 114.291C152.136 114.564 151.636 113.156 150.91 110.841C150.138 108.525 149.185 107.163 151.137 105.938Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M152.589 107.027C153.543 106.392 155.041 107.799 155.722 109.615C156.403 111.431 156.085 113.383 154.996 113.61C153.588 113.883 153.134 112.793 152.499 110.977C151.818 109.161 151.001 108.071 152.635 107.027H152.589Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M151.909 106.846C153.044 106.301 154.542 108.072 154.996 110.16C155.45 112.294 154.86 114.382 153.634 114.473C152.045 114.609 151.727 113.293 151.228 111.159C150.774 109.025 150.002 107.754 151.909 106.8V106.846Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M152.544 107.618C153.316 107.164 154.451 108.798 154.86 110.659C155.268 112.521 154.95 114.337 154.042 114.382C152.907 114.382 152.635 113.293 152.226 111.431C151.818 109.57 151.228 108.389 152.544 107.663V107.618Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M152.817 108.661C153.361 108.343 154.179 109.478 154.496 110.84C154.814 112.157 154.542 113.474 153.906 113.519C153.089 113.519 152.907 112.747 152.59 111.385C152.272 110.023 151.863 109.206 152.817 108.661Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M148.958 118.195C147.686 118.649 146.37 116.425 146.188 114.019C146.007 111.613 146.915 109.342 148.277 109.433C150.002 109.569 150.138 111.068 150.365 113.474C150.547 115.88 151.182 117.469 149.003 118.195H148.958Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M147.823 116.788C146.779 117.152 145.598 115.472 145.417 113.52C145.19 111.613 145.916 109.751 147.051 109.797C148.504 109.842 148.64 111.023 148.867 112.975C149.094 114.882 149.639 116.153 147.823 116.788Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="1.1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M149.185 117.287C148.05 117.787 146.642 115.971 146.234 113.837C145.825 111.703 146.506 109.615 147.777 109.569C149.048 109.524 149.639 110.841 150.047 112.974C150.456 115.108 151.137 116.425 149.185 117.287Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M148.322 116.515C147.459 116.742 146.733 114.926 146.778 113.02C146.778 111.113 147.55 109.433 148.458 109.569C149.366 109.705 149.593 110.977 149.548 112.883C149.548 114.79 149.82 116.061 148.322 116.47V116.515Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M148.867 115.925C148.277 116.198 147.551 115.063 147.278 113.701C147.006 112.339 147.324 111.067 147.959 111.067C148.776 111.067 148.958 111.885 149.185 113.247C149.412 114.609 149.821 115.426 148.822 115.925H148.867Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M145.734 98.2649C146.551 97.5839 148.095 98.8097 148.867 100.49C149.684 102.169 149.593 104.031 148.594 104.349C147.595 104.666 146.824 103.713 146.006 102.033C145.189 100.353 144.327 99.3999 145.689 98.2649H145.734Z"
            fill="white"
            stroke="#242D3C"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M146.914 98.991C147.55 98.4462 148.867 99.3542 149.548 100.671C150.229 101.987 150.183 103.486 149.366 103.803C148.549 104.121 147.868 103.349 147.187 102.033C146.506 100.716 145.779 99.9898 146.869 98.991H146.914Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M146.415 98.9001C147.232 98.3553 148.503 99.5811 149.094 101.125C149.638 102.668 149.411 104.348 148.458 104.575C147.232 104.802 146.869 103.849 146.324 102.305C145.779 100.762 145.053 99.8081 146.415 98.9001Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M146.96 99.4452C147.505 99.0366 148.504 100.126 149.003 101.534C149.502 102.896 149.412 104.348 148.731 104.439C148.05 104.53 147.55 103.758 147.051 102.351C146.551 100.989 146.007 100.126 146.915 99.4452H146.96Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M147.278 100.217C147.686 99.8993 148.413 100.716 148.73 101.715C149.094 102.714 149.048 103.713 148.549 103.804C148.049 103.894 147.686 103.304 147.323 102.305C146.96 101.307 146.551 100.716 147.232 100.217H147.278Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M145.28 107.844C144.326 108.298 143.146 106.754 142.737 104.938C142.374 103.122 142.874 101.306 143.872 101.261C145.189 101.17 145.461 102.305 145.825 104.121C146.188 105.937 146.823 107.072 145.234 107.844H145.28Z"
            fill="white"
            stroke="#242D3C"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M144.236 106.89C143.464 107.299 142.42 106.073 142.057 104.62C141.693 103.167 142.057 101.715 142.965 101.624C144.054 101.533 144.327 102.396 144.644 103.848C145.008 105.301 145.552 106.164 144.236 106.845V106.89Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M145.371 107.163C144.554 107.662 143.283 106.391 142.783 104.802C142.284 103.213 142.602 101.579 143.555 101.397C144.781 101.215 145.099 102.169 145.598 103.758C146.097 105.347 146.778 106.3 145.371 107.163Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M144.644 106.618C144.009 106.89 143.282 105.574 143.146 104.121C142.965 102.668 143.419 101.306 144.1 101.352C144.962 101.397 145.098 102.305 145.235 103.758C145.371 105.211 145.734 106.164 144.644 106.618Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M144.962 106.164C144.554 106.436 143.873 105.619 143.555 104.62C143.237 103.622 143.328 102.623 143.827 102.532C144.327 102.441 144.645 103.077 144.962 104.076C145.28 105.074 145.643 105.665 144.962 106.164Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M137.472 91.6812C137.971 90.7732 139.832 91.2726 141.24 92.4984C142.647 93.7242 143.328 95.4948 142.511 96.1304C141.694 96.766 140.604 96.2212 139.197 94.9954C137.789 93.7696 136.609 93.2248 137.426 91.6358L137.472 91.6812Z"
            fill="white"
            stroke="#242D3C"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M138.879 91.8176C139.242 91.0458 140.831 91.3636 141.966 92.317C143.101 93.2704 143.691 94.6778 143.056 95.268C142.239 95.9944 141.512 95.4496 140.377 94.4962C139.242 93.5428 138.289 93.1796 138.924 91.8176H138.879Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M138.38 91.9536C138.925 91.1364 140.604 91.7266 141.739 92.9524C142.874 94.1782 143.283 95.8126 142.511 96.3574C141.512 97.0838 140.786 96.3574 139.651 95.0862C138.516 93.8604 137.517 93.3156 138.425 91.9082L138.38 91.9536Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M139.06 92.2265C139.424 91.6363 140.786 92.2265 141.784 93.3161C142.783 94.4057 143.283 95.7223 142.692 96.0855C142.102 96.4487 141.33 95.9039 140.332 94.8143C139.333 93.7247 138.47 93.1799 139.06 92.1811V92.2265Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M139.651 92.8614C139.878 92.4528 140.876 92.8614 141.603 93.6332C142.329 94.405 142.647 95.3584 142.238 95.6308C141.694 95.9486 141.24 95.4946 140.559 94.7228C139.832 93.951 139.242 93.5878 139.651 92.8614Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M140.831 100.671C140.15 101.442 138.425 100.489 137.335 98.9908C136.245 97.4472 136.018 95.5858 136.926 95.1318C138.107 94.5416 138.788 95.4496 139.877 96.9932C140.967 98.5368 141.966 99.3086 140.785 100.671H140.831Z"
            fill="white"
            stroke="#242D3C"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M139.514 100.171C138.97 100.852 137.517 100.171 136.654 98.9453C135.792 97.7195 135.519 96.2668 136.291 95.8582C137.063 95.4496 137.835 96.0398 138.743 97.2656C139.651 98.446 140.468 99.0816 139.56 100.217L139.514 100.171Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M140.65 99.9442C140.105 100.716 138.425 100.08 137.335 98.8092C136.246 97.538 135.883 95.9036 136.7 95.3588C137.744 94.6778 138.425 95.4496 139.515 96.7208C140.604 97.992 141.603 98.5822 140.65 99.9442Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M139.741 99.7629C139.242 100.262 138.062 99.3089 137.381 98.0377C136.7 96.7665 136.518 95.3591 137.154 95.1321C137.789 94.9051 138.425 95.6315 139.151 96.9027C139.878 98.1739 140.559 98.9003 139.741 99.7629Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M139.878 99.1726C139.605 99.5812 138.652 99.1272 137.971 98.31C137.29 97.5382 136.972 96.5395 137.381 96.3125C137.789 96.0855 138.334 96.494 139.015 97.2658C139.696 98.0376 140.286 98.4916 139.832 99.1726H139.878Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M129.435 89.0935C129.481 88.2309 131.161 87.9585 132.659 88.4579C134.157 88.9573 135.292 90.0923 134.883 90.8641C134.475 91.6359 133.431 91.5905 131.932 91.0911C130.434 90.5917 129.345 90.5917 129.435 89.0935Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M130.571 88.7303C130.571 88.0039 131.933 87.7315 133.158 88.0947C134.384 88.4579 135.292 89.3659 134.974 90.0015C134.566 90.8641 133.839 90.6371 132.614 90.3193C131.388 89.9561 130.525 90.0015 130.571 88.7303Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M130.207 89.0484C130.344 88.2312 131.842 88.1404 133.158 88.7306C134.475 89.2754 135.338 90.4104 134.884 91.1368C134.339 92.0448 133.522 91.6816 132.205 91.1368C130.888 90.592 129.935 90.4558 130.162 89.0938L130.207 89.0484Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M130.843 89.0026C130.934 88.4124 132.205 88.4578 133.34 88.9572C134.475 89.4566 135.292 90.3192 134.974 90.8186C134.656 91.318 133.839 91.1364 132.704 90.637C131.569 90.1376 130.707 90.0014 130.843 89.0026Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M131.524 89.2752C131.569 88.8666 132.477 88.8666 133.294 89.2752C134.112 89.6838 134.702 90.274 134.475 90.5918C134.248 90.9096 133.658 90.8188 132.84 90.4556C132.023 90.0924 131.433 90.0016 131.524 89.2752Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M135.065 94.9505C134.792 95.8131 133.112 95.6315 131.796 94.8143C130.479 93.9971 129.617 92.6351 130.207 91.9541C130.797 91.2731 131.796 91.5909 133.112 92.4081C134.429 93.2253 135.519 93.4977 135.065 94.9505Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.9"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M133.884 95.0413C133.657 95.7677 132.341 95.6769 131.251 95.0413C130.162 94.4057 129.481 93.3161 129.935 92.7259C130.389 92.1357 131.206 92.3627 132.295 92.9983C133.385 93.6339 134.248 93.8155 133.884 94.9959V95.0413Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M134.656 94.4963C134.474 95.3135 132.976 95.3589 131.705 94.7233C130.434 94.0877 129.571 92.9527 130.071 92.2717C130.57 91.5907 131.433 91.7723 132.704 92.3625C133.975 92.9981 134.928 93.1343 134.656 94.4963Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M133.93 94.6327C133.748 95.1775 132.477 94.8597 131.478 94.0879C130.48 93.3161 129.889 92.2719 130.298 91.9087C130.707 91.5455 131.478 91.8633 132.477 92.6351C133.476 93.4069 134.248 93.7247 133.93 94.6781V94.6327Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M133.839 94.1329C133.794 94.5415 132.886 94.4961 132.069 94.1329C131.251 93.7243 130.707 93.0887 130.934 92.7709C131.161 92.4531 131.751 92.5893 132.568 92.9525C133.385 93.3611 133.975 93.4519 133.839 94.1783V94.1329Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M124.078 87.5953C124.124 86.8689 125.486 86.6873 126.666 87.0959C127.847 87.5045 128.8 88.4125 128.482 89.0481C128.164 89.6837 127.302 89.6383 126.076 89.2297C124.85 88.8211 123.988 88.8211 124.033 87.5953H124.078Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M124.987 87.323C124.987 86.7328 126.076 86.5058 127.075 86.8236C128.074 87.1414 128.8 87.8224 128.528 88.3672C128.21 89.0482 127.62 88.912 126.621 88.5942C125.622 88.3218 124.941 88.3218 124.987 87.323Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M124.714 87.5503C124.85 86.9147 126.031 86.8239 127.075 87.2779C128.119 87.7319 128.845 88.6399 128.482 89.2301C128.028 89.9565 127.393 89.6841 126.348 89.2301C125.304 88.7761 124.532 88.6853 124.714 87.5957V87.5503Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M125.259 87.5505C125.304 87.0965 126.348 87.0965 127.256 87.5505C128.164 88.0045 128.845 88.6855 128.573 89.0487C128.3 89.4119 127.665 89.3211 126.757 88.9125C125.849 88.5039 125.168 88.4131 125.259 87.5959V87.5505Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M125.803 87.7774C125.803 87.4596 126.575 87.4596 127.256 87.7774C127.937 88.0952 128.391 88.5946 128.21 88.867C128.028 89.1394 127.574 89.0486 126.893 88.7762C126.212 88.5038 125.758 88.413 125.803 87.8228V87.7774Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M128.664 92.3622C128.437 93.0432 127.12 92.907 126.031 92.226C124.941 91.545 124.26 90.4554 124.714 89.9106C125.168 89.3658 125.985 89.5928 127.075 90.2738C128.164 90.9548 129.027 91.1818 128.664 92.3168V92.3622Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M127.665 92.4072C127.483 92.9974 126.394 92.952 125.531 92.4072C124.623 91.9078 124.078 90.9998 124.441 90.5458C124.805 90.0918 125.486 90.228 126.348 90.7728C127.211 91.3176 127.937 91.4084 127.665 92.4072Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M128.346 91.9539C128.21 92.5895 126.984 92.6349 125.94 92.1355C124.896 91.6361 124.215 90.6827 124.623 90.1379C125.032 89.5931 125.758 89.7293 126.757 90.2287C127.801 90.7281 128.573 90.8189 128.346 91.9539Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M127.756 92.0899C127.574 92.5439 126.575 92.2715 125.803 91.6813C124.986 91.0911 124.532 90.2285 124.85 89.9107C125.168 89.5929 125.803 89.9107 126.621 90.5009C127.438 91.0911 128.073 91.3635 127.801 92.1353L127.756 92.0899Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M127.665 91.6811C127.619 91.9989 126.893 91.9535 126.212 91.6811C125.531 91.4087 125.122 90.8185 125.304 90.5461C125.485 90.2737 125.939 90.3645 126.62 90.6823C127.256 91.0001 127.755 91.0909 127.665 91.6811Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M118.313 86.4605C118.358 85.7341 119.72 85.5526 120.901 85.9612C122.126 86.3698 123.034 87.2778 122.717 87.9134C122.399 88.549 121.536 88.5035 120.31 88.0949C119.085 87.6863 118.222 87.6863 118.267 86.4605H118.313Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M119.266 86.1882C119.266 85.598 120.356 85.371 121.355 85.6888C122.353 85.9612 123.08 86.6876 122.807 87.2324C122.49 87.9134 121.899 87.7772 120.901 87.4594C119.902 87.187 119.221 87.187 119.266 86.1882Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M118.948 86.4155C119.085 85.7799 120.265 85.6891 121.309 86.1431C122.353 86.5971 123.08 87.5051 122.717 88.0953C122.263 88.8217 121.627 88.5493 120.583 88.0953C119.539 87.6413 118.767 87.5505 118.948 86.4609V86.4155Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M119.493 86.3698C119.538 85.9158 120.583 85.9158 121.491 86.3698C122.399 86.7784 123.08 87.5048 122.807 87.868C122.489 88.3674 121.899 88.1404 120.991 87.7318C120.083 87.3232 119.402 87.2324 119.493 86.4152V86.3698Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M120.038 86.5967C120.038 86.2789 120.81 86.2789 121.491 86.5967C122.172 86.8691 122.626 87.414 122.444 87.6864C122.217 88.0496 121.808 87.8679 121.127 87.5955C120.446 87.3231 119.992 87.2324 120.038 86.6422V86.5967Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M122.898 91.1825C122.671 91.8635 121.355 91.7273 120.265 91.0463C119.175 90.3653 118.494 89.2757 118.948 88.7309C119.402 88.1861 120.22 88.4131 121.309 89.0941C122.399 89.7751 123.261 90.0021 122.898 91.1371V91.1825Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M121.899 91.2275C121.718 91.8177 120.628 91.7723 119.765 91.2275C118.857 90.7281 118.313 89.8201 118.676 89.3661C119.039 88.9121 119.72 89.0483 120.583 89.5931C121.491 90.0925 122.172 90.2287 121.899 91.2275Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M122.58 90.8197C122.444 91.4553 121.218 91.5007 120.174 91.0013C119.13 90.5019 118.449 89.5485 118.858 89.0037C119.357 88.2773 119.993 88.5951 120.991 89.0945C121.99 89.5939 122.807 89.6847 122.58 90.8197Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M121.99 90.9102C121.808 91.3642 120.81 91.0918 120.038 90.5016C119.221 89.9114 118.767 89.0488 119.084 88.731C119.402 88.4132 120.038 88.731 120.855 89.3212C121.672 89.9114 122.308 90.1838 122.035 90.9556L121.99 90.9102Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M121.899 90.5473C121.854 90.8651 121.127 90.8197 120.446 90.5473C119.765 90.2749 119.357 89.6847 119.538 89.4123C119.72 89.1399 120.174 89.2307 120.855 89.5485C121.49 89.8663 121.99 89.9571 121.899 90.5473Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M112.138 86.7782C112.048 86.0518 113.319 85.5978 114.59 85.734C115.861 85.8702 116.951 86.5512 116.769 87.2776C116.542 88.1856 115.77 88.0948 114.499 87.9586C113.228 87.8224 112.365 87.9586 112.184 86.7782H112.138Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M112.955 86.324C112.865 85.7338 113.863 85.3252 114.862 85.3706C115.861 85.416 116.769 86.0062 116.633 86.5964C116.451 87.3228 115.861 87.3228 114.817 87.232C113.773 87.1412 113.092 87.3228 112.955 86.324Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M112.729 86.5964C112.729 85.9154 113.864 85.5976 114.999 85.8246C116.134 86.0516 116.996 86.778 116.769 87.4136C116.497 88.2308 115.77 88.0946 114.681 87.8676C113.546 87.6406 112.774 87.7314 112.729 86.5964Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M113.228 86.46C113.183 86.006 114.181 85.779 115.18 86.006C116.179 86.233 116.996 86.7778 116.815 87.1864C116.633 87.595 115.997 87.6404 114.999 87.4134C114 87.2318 113.319 87.2318 113.228 86.46Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M113.818 86.5511C113.818 86.2333 114.499 86.0517 115.226 86.2333C115.952 86.3695 116.497 86.7781 116.361 87.0959C116.225 87.4137 115.771 87.4137 115.044 87.2775C114.318 87.1413 113.864 87.1413 113.773 86.5965L113.818 86.5511Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M117.586 90.4556C117.541 91.182 116.179 91.3182 114.953 90.9096C113.727 90.501 112.865 89.5476 113.183 88.912C113.591 88.0948 114.363 88.3672 115.589 88.7758C116.815 89.1844 117.677 89.2298 117.586 90.4556Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M116.678 90.6822C116.678 91.2724 115.543 91.454 114.59 91.1362C113.636 90.8184 112.91 90.092 113.137 89.5472C113.364 89.0024 114.09 89.0478 115.044 89.3656C115.997 89.6834 116.724 89.6834 116.678 90.6822Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M117.223 90.1378C117.223 90.8188 116.043 91.0912 114.953 90.8188C113.864 90.5464 113.001 89.7746 113.228 89.1844C113.546 88.4126 114.227 88.5488 115.362 88.8212C116.497 89.0936 117.269 89.0482 117.269 90.1832L117.223 90.1378Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M116.633 90.3652C116.587 90.8192 115.543 90.7738 114.635 90.3652C113.727 89.9566 113.046 89.2302 113.318 88.8216C113.682 88.3222 114.226 88.5946 115.134 89.0032C116.042 89.4118 116.723 89.548 116.633 90.3652Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M116.497 90.0019C116.497 90.3197 115.77 90.4559 115.089 90.2743C114.408 90.0927 113.818 89.6841 113.954 89.3663C114.09 89.0485 114.544 89.0485 115.271 89.2301C115.997 89.4117 116.451 89.4117 116.497 89.9565V90.0019Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M106.917 90.1381C106.554 89.5025 107.598 88.6399 108.824 88.3221C110.05 88.0043 111.321 88.3221 111.412 89.0031C111.502 89.9111 110.731 90.1381 109.505 90.4559C108.279 90.7737 107.507 91.2277 106.917 90.1835V90.1381Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M107.553 89.4116C107.235 88.9122 108.052 88.1404 109.051 87.868C110.05 87.5956 111.048 87.7772 111.139 88.3674C111.23 89.1392 110.64 89.3208 109.641 89.5932C108.642 89.8656 108.052 90.2742 107.553 89.4116Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M107.462 89.7749C107.189 89.1393 108.188 88.4583 109.323 88.2767C110.458 88.0951 111.548 88.5037 111.548 89.1393C111.548 90.0019 110.867 90.0927 109.732 90.2743C108.597 90.4559 107.87 90.8191 107.462 89.7749Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M107.87 89.5024C107.643 89.0938 108.551 88.549 109.55 88.3674C110.549 88.2312 111.502 88.4582 111.502 88.9122C111.502 89.3662 110.867 89.6386 109.868 89.7748C108.869 89.911 108.233 90.1834 107.87 89.457V89.5024Z"
            fill="white"
            stroke="#242D3C"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M108.46 89.3659C108.324 89.0481 108.96 88.6849 109.641 88.5487C110.367 88.4579 111.048 88.5941 111.003 88.9573C110.957 89.3205 110.549 89.4567 109.822 89.5929C109.096 89.6837 108.642 89.9107 108.415 89.3659H108.46Z"
            fill="#242D3C"
            stroke="white"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M171.612 177.26C155.859 178.305 135.973 178.759 115.226 178.759C68.9175 178.759 30.1913 176.534 25.5151 171.404L58.0669 171.268C63.1517 172.902 82.5829 173.946 115.226 173.946C131.343 173.946 144.236 173.674 153.77 173.22L171.612 177.215V177.26Z"
            fill="#8DD5D8"
        />
        <path
            d="M111.003 79.332L110.186 81.2388L109.278 83.5088L108.915 84.326L113.364 86.1874L115.452 81.1934L111.003 79.332Z"
            fill="#5D6D7E"
        />
        <path
            d="M114.726 89.4113L108.324 104.802C107.825 105.937 106.917 106.936 105.691 107.435C104.465 107.934 103.103 107.934 101.923 107.435C100.743 106.936 99.7893 106.028 99.2899 104.802C98.7905 103.576 98.8359 102.214 99.2899 101.079L105.691 85.6885L114.726 89.4567V89.4113Z"
            fill="url(#paint0_linear_669_9408)"
        />
        <path
            d="M112.592 88.5488L113.818 89.0482L107.416 104.439C107.008 105.392 106.236 106.164 105.283 106.573C104.284 106.981 103.24 106.981 102.241 106.573C102.059 106.482 101.923 106.346 101.878 106.209C101.832 106.073 101.787 105.846 101.878 105.71C102.014 105.347 102.422 105.211 102.74 105.347C103.376 105.619 104.102 105.619 104.738 105.347C105.373 105.074 105.873 104.575 106.145 103.939L112.547 88.5488H112.592Z"
            fill="#0299D2"
        />
        <path
            d="M105.691 85.6432L114.726 89.4114L114.998 88.7304C115.18 88.3218 115.18 87.8678 114.998 87.4138C114.817 86.9598 114.499 86.642 114.09 86.5058L108.188 84.0542C107.78 83.8726 107.326 83.8726 106.872 84.0542C106.418 84.2358 106.1 84.599 105.964 84.9622L105.691 85.6432Z"
            fill="#34495E"
        />
        <path
            d="M113.046 39.9706C124.351 35.2944 137.29 40.697 141.92 52.0016C146.597 63.3062 141.194 76.2452 129.889 80.876C118.585 85.5522 105.646 80.1496 101.015 68.845C96.3843 57.5404 101.741 44.6014 113.046 39.9706Z"
            fill="#34495E"
        />
        <path
            d="M103.24 67.9367C107.371 78.0155 118.903 82.8279 129.027 78.6511C139.106 74.5197 143.918 62.9881 139.741 52.8639C135.61 42.7851 124.078 37.9727 113.954 42.1495C103.875 46.2809 99.0631 57.8125 103.24 67.9367Z"
            fill="#5D6D7E"
        />
        <path
            d="M104.692 67.3469C108.506 76.6085 119.13 81.0577 128.391 77.1987C137.653 73.3851 142.057 62.7615 138.243 53.4999C134.429 44.2383 123.806 39.8345 114.544 43.6481C105.283 47.4617 100.833 58.0853 104.692 67.3469Z"
            fill="#CEE2F2"
        />
        <path
            d="M135.701 55.0886C136.018 54.6346 136.064 54.0444 135.837 53.4996C134.747 51.2296 133.204 49.3228 131.206 47.7792C130.525 47.2344 129.481 47.3706 128.981 48.097C128.437 48.778 128.573 49.8222 129.299 50.3216C130.888 51.5474 132.114 53.091 133.022 54.8616C133.385 55.6788 134.339 55.9966 135.156 55.588C135.428 55.4518 135.655 55.2702 135.791 55.0432L135.701 55.0886Z"
            fill="white"
        />
        <path
            d="M126.893 47.5973C126.984 47.4611 127.029 47.3249 127.075 47.1887C127.347 46.3261 126.848 45.4635 125.985 45.1911C123.488 44.4193 120.855 44.3285 118.267 44.8279C115.225 45.4181 112.456 46.9163 110.277 49.0955C109.641 49.7311 109.641 50.7299 110.277 51.3655C110.912 52.0011 111.911 52.0011 112.547 51.3655C114.317 49.6403 116.496 48.4599 118.948 47.9605C120.991 47.5519 123.125 47.6427 125.077 48.2329C125.758 48.4145 126.484 48.1421 126.848 47.5519L126.893 47.5973Z"
            fill="white"
        />
        <path
            d="M100.107 100.898C99.8802 101.715 99.8348 103.622 100.97 104.62C101.56 105.165 102.604 105.483 103.285 105.12C104.375 104.53 104.057 102.35 104.284 102.35C104.511 102.35 105.601 105.211 104.193 106.981C102.695 108.888 99.4262 108.025 98.609 107.798C96.3844 107.208 93.7966 105.438 93.161 103.077C91.9806 98.7637 97.5194 94.1329 99.3808 92.5439"
            fill="#242D3C"
        />
        <defs>
            <linearGradient
                id="paint0_linear_669_9408"
                x1="97.3665"
                y1="99.5869"
                x2="115.96"
                y2="92.9296"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.1" stop-color="#21B3DA" />
                <stop offset="1" stop-color="#0299D2" />
            </linearGradient>
        </defs>
    </svg>
</template>
