<template>
    <span
        v-if="isAllChannels"
        class="px-1 py-0.5 text-xs rounded-md bg-surface-600 text-surface-200"
    >
        All Channels
    </span>
    <span
        v-else
        class="px-1 py-0.5 text-xs rounded-md bg-surface-200 text-surface-600"
    >
        {{ capitalizedChannel }}
    </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
    channel: {
        type: String,
        required: true
    },
    isAllChannels: {
        type: Boolean,
        required: false,
        default: false
    }
})

const capitalizedChannel = computed(() => {
    return props.channel.slice(0, 1).toUpperCase() + props.channel.slice(1)
})
</script> 