<template>
    <div class="max-w-3xl mx-auto p-4">
        <Card>
            <template #title>
                <div class="text-xl font-bold">{{ title }}</div>
            </template>
            <template #subtitle>
                <div class="text-gray-600 text-sm">{{ description }}</div>
            </template>
            <template #content>
                <DataTable
                    :value="sortedVendors"
                    striped-rows
                    class="text-sm"
                    :pt="{
                        column: {
                            columnheadercontent: { class: ['!font-bold'] }
                        }
                    }"
                >
                    <Column field="name" header="Vendor">
                        <template #body="slotProps">
                            <a
                                v-if="slotProps.data.link"
                                :href="slotProps.data.link"
                                target="_blank"
                                rel="noopener"
                                class="text-blue-600 hover:underline"
                            >
                                {{ slotProps.data.name }}
                            </a>
                            <span v-else>{{ slotProps.data.name }}</span>
                        </template>
                    </Column>
                    <Column field="function" header="Function"></Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const title = 'PerformLine Third-Party Vendors'
const description = 'PerformLine utilizes the following vendors for service delivery:'
const vendors = [
    { name: 'Amazon Web Services', function: 'Hosting, Storage, Compute', link: 'https://aws.amazon.com' },
    { name: 'CloudFlare', function: 'Site Acceleration and Security', link: 'https://www.cloudflare.com' },
    { name: 'Nice/InContact', function: 'Call and Chat Integration', link: 'https://www.nice.com' },
    { name: 'Prompt.io', function: 'Chat Integration', link: 'https://www.prompt.io' },
    { name: 'Ring Central', function: 'Call Integration', link: 'https://www.ringcentral.com' },
    { name: 'SnapEngage', function: 'Chat Integration', link: 'https://snapengage.com' },
    { name: 'Twilio', function: 'Chat Integration', link: 'https://www.twilio.com' },
    { name: 'LivePerson', function: 'Audio Transcription', link: 'https://www.liveperson.com' },
    { name: 'Pendo', function: 'User analytics', link: 'https://www.pendo.io' },
    { name: 'Sentry', function: 'Application Monitoring', link: 'https://sentry.io' },
    { name: 'Mailchimp', function: 'Email Automation', link: 'https://mailchimp.com' },
    { name: 'Zapier', function: 'Integration Automation', link: 'https://zapier.com' },
    { name: 'Comscore', function: 'Media analytics platform', link: 'https://www.comscore.com' },
    { name: 'Intercom', function: 'Customer Service platform', link: 'https://www.intercom.com' },
    { name: 'Five9', function: 'Call and Chat Integration', link: 'https://www.five9.com' }
]

const sortedVendors = computed(() => {
    return [...vendors].sort((a, b) => a.name.localeCompare(b.name))
})
</script>
