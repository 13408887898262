<template>
    <TableHeader
        title="Team directory"
        :has-selected-rows="false"
        :has-active-filters="hasActiveFilters"
        @clear-all-filters="clearAllFilters"
    >
        <template #filterSection>
            <div class="filters flex gap-2">
                <FilterUserRole
                    :user-roles="role"
                    :users-summary="usersSummary"
                    @get-users-summary-info="onGetUsersSummaryInfo"
                    @update-user-role-filter="onUpdateUserRoleFilter"
                    @remove-user-role-filter="onRemoveUserRoleFilter"
                />
                <FilterUserStatus
                    :active="active"
                    :inactive="inactive"
                    @update-user-active-filter="onUpdateUserStatusFilter"
                    @remove-user-active-filter="onRemoveUserStatusFilter"
                />
                <FilterDeactivatedAt
                    :deactivated-at-before="deactivatedAtBefore"
                    :deactivated-at-after="deactivatedAtAfter"
                    @update-deactivated-at-filter="onUpdateDeactivatedAtFilter"
                    @remove-deactivated-at-filter="onRemoveDeactivatedAtFilter"
                />

                <FilterLastLogin
                    :last-login-before="lastLoginBefore"
                    :last-login-after="lastLoginAfter"
                    @update-last-login-filter="onUpdateLastLoginFilter"
                    @remove-last-login-filter="onRemoveLastLoginFilter"
                />
                <FilterCreatedAt
                    :created-at-before="createdAtBefore"
                    :created-at-after="createdAtAfter"
                    @update-created-at-filter="onUpdateCreatedAtFilter"
                    @remove-created-at-filter="onRemoveCreatedAtFilter"
                />
            </div>
        </template>

        <template #right>
            <div class="flex items-center gap-2 mr-2">
                <IconField>
                    <InputIcon class="far fa-search" />
                    <InputText v-model="searchQueryInput" size="small" placeholder="Search user" class="pl-10" />
                </IconField>
            </div>
            <!-- Export button -->
            <Button
                type="button"
                size="small"
                label="Export"
                icon="far fa-file-export"
                rounded
                :pt="{ root: { class: ['bg-primary-200 !text-primary-500 border-none focus:ring-0 hover:!bg-primary-300 focus:!bg-primary-300'] }, icon: { class: ['!mr-0'] } }"
                @click="emit('export-users')"
            />
        </template>
    </TableHeader>
</template>

<script setup lang="ts">
import { watch, computed, onUnmounted, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import FilterCreatedAt from '@/components/dataTable/tableFilter/filters/FilterCreatedAt.vue'
import FilterLastLogin from '@/components/dataTable/tableFilter/filters/FilterLastLogin.vue'
import FilterDeactivatedAt from '@/components/dataTable/tableFilter/filters/FilterDeactivatedAt.vue'
import FilterUserRole from '@/components/dataTable/tableFilter/filters/FilterUserRole.vue'
import FilterUserStatus from '@/components/dataTable/tableFilter/filters/FilterUserStatus.vue'
import { useCompanyUserStore } from '@/stores/companyUsersStore'
import { storeToRefs } from 'pinia'
import TableHeader from '@/components/dataTable/TableHeader.vue'

const emit = defineEmits(['get-users-summary-info', 'export-users'])

const companyUserStore = useCompanyUserStore()
const {
    searchQuery,
    createdAtBefore,
    createdAtAfter,
    lastLoginBefore,
    lastLoginAfter,
    deactivatedAtBefore,
    deactivatedAtAfter,
    role,
    active,
    inactive,
    usersSummary
} = storeToRefs(companyUserStore)

const debouncedUpdateSearchQuery = useDebounceFn(
    (value: string) => {
        companyUserStore.updateSearchQuery(value)
    },
    400,
    { maxWait: 3000 }
)

const searchQueryInput = ref('')

watch(searchQueryInput, (newValue) => {
    debouncedUpdateSearchQuery(newValue)
})

watch(searchQuery, (newValue) => {
    searchQueryInput.value = newValue
})

onUnmounted(() => {
    companyUserStore.removeCreatedAtFilter()
    companyUserStore.removeLastLoginFilter()
    companyUserStore.removeDeactivatedAtFilter()
    companyUserStore.updateSearchQuery('')
})

const onUpdateCreatedAtFilter = (filter: { createdAtBefore: string; createdAtAfter: string }) => {
    companyUserStore.updateCreatedAtFilter(filter)
}

const onRemoveCreatedAtFilter = () => {
    companyUserStore.removeCreatedAtFilter()
}

const onUpdateLastLoginFilter = (filter: { lastLoginBefore: string; lastLoginAfter: string }) => {
    companyUserStore.updateLastLoginFilter(filter)
}

const onRemoveLastLoginFilter = () => {
    companyUserStore.removeLastLoginFilter()
}

const onUpdateDeactivatedAtFilter = (filter: { deactivatedAtBefore: string; deactivatedAtAfter: string }) => {
    companyUserStore.updateDeactivatedAtFilter(filter)
}

const onRemoveDeactivatedAtFilter = () => {
    companyUserStore.removeDeactivatedAtFilter()
}

const onUpdateUserRoleFilter = (filter: { role: string }) => {
    companyUserStore.updateUserRoleFilter(filter)
}

const onRemoveUserRoleFilter = () => {
    companyUserStore.removeUserRoleFilter()
}

const onUpdateUserStatusFilter = (filter: { active: boolean; inactive: boolean }) => {
    companyUserStore.updateUserStatusFilter(filter)
}

const onRemoveUserStatusFilter = () => {
    companyUserStore.removeUserStatusFilter()
}

const onGetUsersSummaryInfo = () => {
    emit('get-users-summary-info')
}

const hasActiveFilters = computed(() => {
    return (
        createdAtBefore.value !== undefined ||
        createdAtAfter.value !== undefined ||
        lastLoginBefore.value !== undefined ||
        lastLoginAfter.value !== undefined ||
        deactivatedAtBefore.value !== undefined ||
        deactivatedAtAfter.value !== undefined ||
        role.value !== undefined ||
        active.value !== undefined ||
        inactive.value !== undefined
    )
})

const clearAllFilters = () => {
    companyUserStore.removeCreatedAtFilter()
    companyUserStore.removeLastLoginFilter()
    companyUserStore.removeDeactivatedAtFilter()
    companyUserStore.removeUserRoleFilter()
    companyUserStore.removeUserStatusFilter()
}
</script>
