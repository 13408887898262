<template>
    <div class="border-b border-gray-200 py-6">
        <div class="flex gap-2 items-center justify-between">
            <div>
                <div class="flex items-center gap-2">
                    <h1 class="text-xl font-medium">{{ user?.firstName }} {{ user?.lastName }}</h1>
                    <Button icon="far fa-pen" text aria-label="Edit name" class="p-0" @click="startEditingName" />
                </div>
                <p class="text-gray-600">{{ user?.email }}</p>
            </div>
            <Button label="Log out" text size="small" icon="far fa-sign-out" @click="handleLogout" />
        </div>

        <!-- Inline Name Edit Form -->
        <div v-if="isEditingName" class="mt-6 p-6 bg-blue-50/50 rounded-lg space-y-4">
            <h3 class="font-medium">Update display name</h3>
            <div class="mt-2 w-1/2 space-y-4">
                <div>
                    <label class="block text-surface-500 text-xs font-normal">First name</label>
                    <InputText v-model="editedFirstName" placeholder="First name" class="w-full text-sm" size="small" />
                </div>
                <div>
                    <label class="block text-surface-500 text-xs font-normal">Last name</label>
                    <InputText v-model="editedLastName" placeholder="Last name" class="w-full text-sm" size="small" />
                </div>
                <div class="flex gap-2">
                    <Button label="Discard" text rounded size="small" @click="cancelNameEdit" />
                    <Button label="Save" :loading="loading" rounded size="small" @click="saveNameChanges" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { useToast } from 'primevue/usetoast'
import { storeToRefs } from 'pinia'

const toast = useToast()
const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const loading = ref(false)

// Name editing
const isEditingName = ref(false)
const editedFirstName = ref('')
const editedLastName = ref('')

const startEditingName = () => {
    editedFirstName.value = user.value?.firstName || ''
    editedLastName.value = user.value?.lastName || ''
    isEditingName.value = true
}

const cancelNameEdit = () => {
    isEditingName.value = false
    editedFirstName.value = ''
    editedLastName.value = ''
}

const saveNameChanges = async () => {
    if (!user.value?.username) return

    loading.value = true
    try {
        await userStore.updateUserName(user.value.username, editedFirstName.value, editedLastName.value)
        isEditingName.value = false
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Your name has been updated successfully',
            life: 3000
        })
    } catch (error) {
        console.error('Failed to update name:', error)
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to update your name. Please try again.',
            life: 3000
        })
    } finally {
        loading.value = false
    }
}

const handleLogout = async () => {
    await userStore.logoutUser()
}
</script>
