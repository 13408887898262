<template>
    <div v-if="!isVendorSignupPage" class="h-10 w-full flex items-center bg-surface-300 border-b border-b-surface-950/[.1]">
        <template v-if="!isVendor">
            <div
                v-if="activeChannel && brandsMappedByChannel"
                id="brand-types-tabs"
                class="h-10 flex text-surface-500 px-6"
                data-testid="brand-options"
            >
                <NavBarOptions
                    v-for="brandData in brandsMappedByChannel"
                    :key="brandData.channel"
                    :brand-data="brandData"
                    :active-brand="activeBrand"
                    :active-channel="activeChannel"
                    :selected-channel="selectedChannel"
                    @set-selected-channel="setSelectedChannel"
                    @clear-selected-channel="clearSelectedChannel"
                    @switch-brand="setBrand"
                ></NavBarOptions>
            </div>
            <div v-else id="brand-types-tabs-loading" class="h-10 flex text-surface-500 px-6 justify-around">
                <div
                    v-for="num in 6"
                    :key="num"
                    class="bg-surface-400/[.5] animate-pulse shadow-sm rounded-lg transition-transform duration-200 ease-in-out h-8 w-28 self-center mx-2"
                ></div>
            </div>
        </template>
        <div class="h-10 ml-auto pr-2 flex justify-center">
            <NavBarMenu v-if="user"></NavBarMenu>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import NavBarOptions from './NavBarOptions.vue'
import { camelCaseToTitleCase } from '@/utils/helpers'
import { useUserStore } from '@/stores/userStore'
import { storeToRefs } from 'pinia'
import NavBarMenu from './NavBarMenu.vue'
import { HelpCenterChannel } from '@/interfaces/HelpCenter'
import type { UserBrandData } from '@/api/auth.api'
import { useRoute } from 'vue-router'

const userStore = useUserStore()
const { user, userBrands, activeBrand, activeChannel, isVendor } = storeToRefs(userStore)
const route = useRoute()

const selectedChannel = ref<HelpCenterChannel>()

const setSelectedChannel = (channel: HelpCenterChannel, hasMultipleBrands: boolean): void => {
    selectedChannel.value = channel
    if (!hasMultipleBrands) {
        const brand = userBrands.value.find((brand: UserBrandData) => brand.channel === channel)
        if (brand) {
            setBrand(brand.id, channel)
        }
    }
}

onMounted(async () => {
    await userStore.handleMasqueradeInit()
})

const clearSelectedChannel = () => {
    selectedChannel.value = undefined
}

const setBrand = (groupId: string, channel: HelpCenterChannel): void => {
    const nextUrl = channel === HelpCenterChannel.Document ? '/documents' : '/pages/browse/list'
    let navUrl = '/accounts/v1/me/activate-group/?group=' + groupId
    navUrl += `&next=${nextUrl}`
    navUrl += '&errnext=/login'
    window.location.href = navUrl
}

const brandsMappedByChannel = computed(() => {
    return Object.values(HelpCenterChannel).map((channel) => ({
        channel: channel,
        label: camelCaseToTitleCase(channel),
        brands: userBrands.value.filter((brand) => brand.channel == channel)
    }))
})

const isVendorSignupPage = computed(() => {
    return route.path && route.path.includes('/vendor/signup')
})
</script>
