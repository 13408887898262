<template>
    <svg width="437" height="202" viewBox="0 0 437 202" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.05">
            <path
                d="M45.4458 49.4998C45.7754 50.0707 46.1078 50.639 46.4429 51.2049L349.533 -123.784C349.21 -124.357 348.884 -124.929 348.555 -125.5C348.28 -125.976 348.003 -126.45 347.725 -126.922L44.6292 48.0701C44.8989 48.5474 45.1711 49.024 45.4458 49.4998Z"
                fill="#00AAED"
            />
            <path
                d="M49.8657 56.7506C49.2139 55.7366 48.571 54.7138 47.9372 53.6821L350.931 -121.251C351.508 -120.187 352.072 -119.119 352.624 -118.047L49.8657 56.7506Z"
                fill="#00AAED"
            />
            <path
                d="M51.4522 59.1747C52.1248 60.1842 52.8064 61.1847 53.4968 62.1761L355.507 -112.19C354.994 -113.283 354.468 -114.374 353.93 -115.461L51.4522 59.1747Z"
                fill="#00AAED"
            />
            <path
                d="M57.3389 67.4797C56.6094 66.5113 55.8885 65.5335 55.1766 64.5463L356.72 -109.55C357.219 -108.44 357.705 -107.327 358.179 -106.211L57.3389 67.4797Z"
                fill="#00AAED"
            />
            <path
                d="M59.1135 69.7952C59.8655 70.7596 60.6263 71.7144 61.3956 72.6595L360.637 -100.107C360.203 -101.246 359.756 -102.382 359.297 -103.516L59.1135 69.7952Z"
                fill="#00AAED"
            />
            <path
                d="M43.2263 45.54L346.235 -129.402C345.603 -130.435 344.962 -131.459 344.313 -132.474L41.5274 42.339C42.0816 43.4094 42.6479 44.4764 43.2263 45.54Z"
                fill="#00AAED"
            />
            <path
                d="M340.692 -137.906C341.38 -136.913 342.06 -135.912 342.731 -134.901L40.2166 39.7557C39.6767 38.6696 39.1491 37.5802 38.6337 36.4878L340.692 -137.906Z"
                fill="#00AAED"
            />
            <path
                d="M339.017 -140.279C338.307 -141.267 337.588 -142.246 336.861 -143.216L35.951 30.5148C36.4269 31.6296 36.9153 32.7416 37.4162 33.8506L339.017 -140.279Z"
                fill="#00AAED"
            />
            <path
                d="M332.815 -148.402C333.582 -147.455 334.341 -146.499 335.091 -145.534L34.8282 27.8229C34.367 26.6907 33.9185 25.5556 33.4826 24.418L332.815 -148.402Z"
                fill="#00AAED"
            />
            <path
                d="M330.949 -150.664C330.158 -151.607 329.358 -152.539 328.55 -153.461L31.2328 18.195C31.6277 19.3558 32.0356 20.5144 32.4563 21.6704L330.949 -150.664Z"
                fill="#00AAED"
            />
            <path
                d="M324.062 -158.392C324.912 -157.494 325.753 -156.586 326.585 -155.667L30.3052 15.3905C29.9259 14.2101 29.5597 13.0275 29.2068 11.8428L324.062 -158.392Z"
                fill="#00AAED"
            />
            <path
                d="M321.996 -160.539C321.121 -161.434 320.237 -162.317 319.344 -163.19L27.4105 5.35796C27.7203 6.56707 28.0436 7.77443 28.3804 8.97986L321.996 -160.539Z"
                fill="#00AAED"
            />
            <path
                d="M314.389 -167.851C315.326 -167.004 316.254 -166.146 317.174 -165.277L26.6884 2.43481C26.3956 1.2035 26.1165 -0.0294196 25.8513 -1.26376L314.389 -167.851Z"
                fill="#00AAED"
            />
            <path
                d="M25.2372 -4.24923L312.111 -169.876C311.145 -170.719 310.171 -171.551 309.189 -172.371L24.5378 -8.0273C24.7567 -6.76681 24.9899 -5.50739 25.2372 -4.24923Z"
                fill="#00AAED"
            />
            <path
                d="M24.0359 -11.0776L306.798 -174.33C305.784 -175.147 304.762 -175.951 303.732 -176.742L23.4801 -14.9386C23.6508 -13.6508 23.836 -12.3637 24.0359 -11.0776Z"
                fill="#00AAED"
            />
            <path
                d="M74.9004 87.4282C74.006 86.5567 73.1198 85.6743 72.2421 84.7812L365.711 -84.6533C366.046 -83.4466 366.367 -82.2379 366.674 -81.0276L74.9004 87.4282Z"
                fill="#00AAED"
            />
            <path
                d="M77.0762 89.5121C77.9984 90.3801 78.9289 91.2368 79.8675 92.0824L368.221 -74.3988C367.958 -75.6344 367.682 -76.8687 367.391 -78.1013L77.0762 89.5121Z"
                fill="#00AAED"
            />
            <path
                d="M85.0808 96.5944C84.0963 95.7759 83.1198 94.9457 82.1515 94.1037L368.83 -71.4101C369.075 -70.1506 369.306 -68.8898 369.522 -67.628L85.0808 96.5944Z"
                fill="#00AAED"
            />
            <path
                d="M87.4773 98.5508C88.4937 99.3656 89.5182 100.168 90.5506 100.958L370.567 -60.7091C370.399 -61.9982 370.216 -63.2867 370.018 -64.5743L87.4773 98.5508Z"
                fill="#00AAED"
            />
            <path
                d="M96.2889 105.167C95.2063 104.407 94.1315 103.633 93.0647 102.847L370.945 -57.5875C371.093 -56.2704 371.225 -54.9529 371.343 -53.6351L96.2889 105.167Z"
                fill="#00AAED"
            />
            <path
                d="M98.9267 106.984C100.047 107.741 101.175 108.484 102.31 109.213L371.835 -46.3977C371.772 -47.7456 371.692 -49.0939 371.597 -50.4421L98.9267 106.984Z"
                fill="#00AAED"
            />
            <path
                d="M108.632 113.085C107.44 112.389 106.256 111.679 105.079 110.954L371.959 -43.129C371.998 -41.7476 372.021 -40.3668 372.028 -38.9868L108.632 113.085Z"
                fill="#00AAED"
            />
            <path
                d="M111.541 114.745C112.778 115.436 114.023 116.111 115.276 116.771L371.898 -31.3902C371.953 -32.8046 371.991 -34.2204 372.011 -35.6373L111.541 114.745Z"
                fill="#00AAED"
            />
            <path
                d="M122.266 120.257C120.948 119.636 119.638 118.999 118.335 118.345L371.731 -27.9538C371.646 -26.4984 371.543 -25.0448 371.422 -23.5933L122.266 120.257Z"
                fill="#00AAED"
            />
            <path
                d="M125.489 121.736C126.864 122.35 128.246 122.946 129.635 123.524L370.567 -15.5778C370.761 -17.0698 370.936 -18.5648 371.091 -20.0623L125.489 121.736Z"
                fill="#00AAED"
            />
            <path
                d="M137.422 126.55C135.954 126.02 134.493 125.469 133.039 124.899L370.056 -11.9428C369.823 -10.3985 369.568 -8.85771 369.294 -7.32093L137.422 126.55Z"
                fill="#00AAED"
            />
            <path
                d="M141.027 127.809C142.571 128.33 144.121 128.828 145.678 129.306L367.553 1.20643C367.918 -0.380601 368.261 -1.97286 368.582 -3.56984L141.027 127.809Z"
                fill="#00AAED"
            />
            <path
                d="M154.982 131.881C153.151 131.427 151.326 130.945 149.511 130.433L366.613 5.08915C366.148 6.91767 365.654 8.73865 365.131 10.5513L154.982 131.881Z"
                fill="#00AAED"
            />
            <path
                d="M159.095 132.846C160.868 133.24 162.648 133.607 164.433 133.946L362.193 19.7691C362.792 18.0534 363.365 16.3287 363.91 14.5958L159.095 132.846Z"
                fill="#00AAED"
            />
            <path
                d="M174.64 135.575C172.709 135.324 170.783 135.042 168.863 134.728L360.656 23.9966C359.968 25.8166 359.249 27.6257 358.5 29.4231L174.64 135.575Z"
                fill="#00AAED"
            />
            <path
                d="M179.461 136.132C181.566 136.345 183.676 136.521 185.791 136.659L353.864 39.6216C354.802 37.7218 355.705 35.8063 356.573 33.8762L179.461 136.132Z"
                fill="#00AAED"
            />
            <path
                d="M198.183 137.026C195.825 137.04 193.469 137.006 191.116 136.924L351.431 44.3663C350.326 46.4443 349.177 48.5018 347.986 50.5375L198.183 137.026Z"
                fill="#00AAED"
            />
            <path
                d="M204.21 136.887C206.923 136.777 209.639 136.604 212.353 136.367L340.33 62.4794C341.893 60.2472 343.4 57.9822 344.852 55.6867L204.21 136.887Z"
                fill="#00AAED"
            />
            <path
                d="M229.462 134.011C226.139 134.638 222.807 135.167 219.472 135.597L336.104 68.2594C334.064 70.9329 331.94 73.5538 329.736 76.118L229.462 134.011Z"
                fill="#00AAED"
            />
            <path
                d="M238.736 131.997C243.677 130.781 248.591 129.342 253.464 127.676L312.248 93.7364C316.128 90.3497 319.831 86.8137 323.354 83.1423L238.736 131.997Z"
                fill="#00AAED"
            />
            <path
                d="M284.5 113.554C281.046 115.549 277.551 117.412 274.023 119.146L294.582 107.276C291.316 109.465 287.955 111.56 284.5 113.554Z"
                fill="#00AAED"
            />
            <path
                d="M67.6412 79.9156C68.4759 80.8331 69.3191 81.7403 70.1707 82.637L364.89 -87.5192C364.539 -88.7051 364.175 -89.8889 363.798 -91.0706L67.6412 79.9156Z"
                fill="#00AAED"
            />
            <path
                d="M63.2668 74.9192C64.0597 75.8604 64.8612 76.7917 65.6712 77.7129L362.875 -93.878C362.482 -95.04 362.077 -96.1998 361.658 -97.357L63.2668 74.9192Z"
                fill="#00AAED"
            />
            <path
                d="M23.0956 -18.0566L301.224 -178.634C300.16 -179.422 299.088 -180.197 298.008 -180.959L22.6904 -22.0045C22.8104 -20.6882 22.9454 -19.3722 23.0956 -18.0566Z"
                fill="#00AAED"
            />
            <path
                d="M292.001 -185.013C293.134 -184.282 294.259 -183.538 295.376 -182.78L22.4293 -25.1938C22.3315 -26.5404 22.2494 -27.8871 22.183 -29.2335L292.001 -185.013Z"
                fill="#00AAED"
            />
            <path
                d="M22.0526 -32.4982L289.239 -186.758C288.065 -187.485 286.884 -188.197 285.695 -188.894L21.9751 -36.6354C21.9846 -35.257 22.0104 -33.8779 22.0526 -32.4982Z"
                fill="#00AAED"
            />
            <path
                d="M279.068 -192.59C280.317 -191.928 281.559 -191.251 282.793 -190.559L21.9841 -39.9806C22.0015 -41.3956 22.036 -42.8096 22.0876 -44.2222L279.068 -192.59Z"
                fill="#00AAED"
            />
            <path
                d="M22.2467 -47.6541L276.017 -194.168C274.717 -194.824 273.41 -195.464 272.096 -196.086L22.5457 -52.0086C22.428 -50.5591 22.3283 -49.1075 22.2467 -47.6541Z"
                fill="#00AAED"
            />
            <path
                d="M264.747 -199.365C266.132 -198.785 267.51 -198.187 268.881 -197.571L22.8678 -55.5346C23.0197 -57.03 23.1906 -58.5227 23.3807 -60.0126L264.747 -199.365Z"
                fill="#00AAED"
            />
            <path
                d="M23.8821 -63.6421L261.353 -200.746C259.903 -201.318 258.446 -201.871 256.982 -202.404L24.6315 -68.2566C24.3613 -66.7222 24.1115 -65.1839 23.8821 -63.6421Z"
                fill="#00AAED"
            />
            <path
                d="M248.751 -205.174C250.303 -204.694 251.849 -204.192 253.388 -203.669L25.3327 -72.0016C25.6491 -73.5957 25.9876 -75.1852 26.3479 -76.7695L248.751 -205.174Z"
                fill="#00AAED"
            />
            <path
                d="M244.931 -206.309C243.291 -206.776 241.643 -207.219 239.99 -207.638L28.5949 -85.5887C28.1312 -83.9474 27.6914 -82.2993 27.2756 -80.6452L244.931 -206.309Z"
                fill="#00AAED"
            />
            <path
                d="M230.611 -209.745C232.383 -209.397 234.149 -209.023 235.908 -208.621L29.7844 -89.6156C30.3163 -91.3401 30.8748 -93.0567 31.4596 -94.7646L230.611 -209.745Z"
                fill="#00AAED"
            />
            <path
                d="M226.218 -210.549C224.315 -210.872 222.406 -211.164 220.492 -211.425L35.0642 -104.368C34.3329 -102.58 33.6314 -100.781 32.9599 -98.9708L226.218 -210.549Z"
                fill="#00AAED"
            />
            <path
                d="M209.455 -212.574C211.547 -212.423 213.635 -212.234 215.718 -212.008L36.9457 -108.794C37.7917 -110.711 38.6725 -112.614 39.5877 -114.501L209.455 -212.574Z"
                fill="#00AAED"
            />
            <path
                d="M204.191 -212.875C201.869 -212.972 199.543 -213.024 197.215 -213.029L45.3136 -125.329C44.1538 -123.31 43.0355 -121.27 41.9592 -119.21L204.191 -212.875Z"
                fill="#00AAED"
            />
            <path
                d="M183.273 -212.502C185.941 -212.71 188.61 -212.856 191.277 -212.941L48.3587 -130.427C49.7657 -132.694 51.2267 -134.932 52.7409 -137.139L183.273 -212.502Z"
                fill="#00AAED"
            />
            <path
                d="M176.302 -211.817C173.055 -211.432 169.812 -210.955 166.576 -210.384L62.9239 -150.54C60.8114 -148.023 58.7763 -145.453 56.8196 -142.834L176.302 -211.817Z"
                fill="#00AAED"
            />
            <path
                d="M143.802 -204.757C148.385 -206.224 153.001 -207.492 157.639 -208.564L68.9686 -157.37C72.216 -160.851 75.6221 -164.214 79.1837 -167.45L143.802 -204.757Z"
                fill="#00AAED"
            />
            <path
                d="M127.078 -198.442C121.126 -195.839 115.258 -192.879 109.5 -189.555C103.742 -186.23 98.2448 -182.628 93.0148 -178.775L127.078 -198.442Z"
                fill="#00AAED"
            />
        </g>
    </svg>
</template>
