<template>
    <div class="border-b border-gray-200 py-6 text-surface-700">
        <div class="flex items-center justify-between h-8">
            <h2 class="text-base font-medium">Password</h2>
            <div v-if="!isSSOEnabled && !isChangingPassword">
                <Button label="Change password" size="small" text @click="togglePasswordChange" />
            </div>
        </div>

        <Message v-if="isSSOEnabled" severity="info" class="mt-2 w-fit text-[#012140]">
            Managed by your business
        </Message>

        <!-- Inline Password Change Form -->
        <div v-if="!isSSOEnabled && isChangingPassword" class="mt-6 p-6 bg-blue-50/50 rounded-lg space-y-4">
            <h3 class="font-medium">Change password</h3>
            <div class="flex gap-4 justify-between mt-2">
                <div class="w-1/2 space-y-4">
                    <div>
                        <label class="block text-surface-500 text-xs font-normal">Current password</label>
                        <Password
                            v-model="currentPassword"
                            toggle-mask
                            size="small"
                            class="w-full text-sm"
                            :feedback="false"
                            :pt="{
                                pcinputtext: {
                                    root: {
                                        class: 'w-full'
                                    }
                                }
                            }"
                        />
                    </div>
                    <div>
                        <label class="block text-surface-500 text-xs font-normal">Enter a new password</label>
                        <Password
                            v-model="newPassword"
                            toggle-mask
                            size="small"
                            class="w-full text-sm"
                            :feedback="false"
                            :pt="{
                                pcinputtext: {
                                    root: {
                                        class: 'w-full'
                                    }
                                }
                            }"
                        />
                    </div>
                    <div>
                        <label
                            class="block text-xs font-normal"
                            :class="[passwordMismatch ? 'text-red-500' : 'text-surface-500']"
                            >Re-enter your new password</label
                        >
                        <Password
                            v-model="confirmPassword"
                            toggle-mask
                            size="small"
                            class="w-full text-sm"
                            :feedback="false"
                            :pt="{
                                pcinputtext: {
                                    root: {
                                        class: `w-full ${passwordMismatch ? '!border-red-500' : ''}`
                                    }
                                }
                            }"
                        />
                        <small v-if="passwordMismatch" class="text-red-500">Your new password does not match.</small>
                    </div>
                </div>
                <div class="w-1/2 flex items-end">
                    <div class="p-2 rounded border border-gray-200 w-full">
                        <p class="font-medium mb-2 text-sm">Passwords must include at least:</p>
                        <ul class="list-disc list-inside ml-1 text-sm">
                            <li :class="[hasMinLength ? 'text-green-800' : 'text-red-500']">8 characters</li>
                            <li :class="[hasLowerCase ? 'text-green-800' : 'text-red-500']">1 lowercase letter</li>
                            <li :class="[hasUpperCase ? 'text-green-800' : 'text-red-500']">1 uppercase letter</li>
                            <li :class="[hasNumber ? 'text-green-800' : 'text-red-500']">1 number</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="passwordError" class="bg-red-500/20 text-red-600 text-left mb-4 px-4 py-2 text-sm w-fit">
                {{ passwordError }}
            </div>
            <div class="flex gap-2">
                <Button label="Cancel" text rounded size="small" @click="cancelPasswordChange" />
                <Button
                    label="Change password"
                    rounded
                    size="small"
                    :disabled="!isPasswordValid"
                    :loading="loading"
                    @click="handleChangePassword"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { useToast } from 'primevue/usetoast'
import { storeToRefs } from 'pinia'

const toast = useToast()
const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const isSSOEnabled = computed(() => user.value?.ssoEnabled)
const loading = ref(false)

// Password change
const isChangingPassword = ref(false)
const currentPassword = ref('')
const newPassword = ref('')
const confirmPassword = ref('')
const passwordError = ref('')

const hasMinLength = computed(() => newPassword.value.length >= 8)
const hasLowerCase = computed(() => /[a-z]/.test(newPassword.value))
const hasUpperCase = computed(() => /[A-Z]/.test(newPassword.value))
const hasNumber = computed(() => /\d/.test(newPassword.value))
const passwordMismatch = computed(() => confirmPassword.value && newPassword.value !== confirmPassword.value)

const isPasswordValid = computed(
    () =>
        currentPassword.value &&
        hasMinLength.value &&
        hasLowerCase.value &&
        hasUpperCase.value &&
        hasNumber.value &&
        !passwordMismatch.value &&
        confirmPassword.value
)

const togglePasswordChange = () => {
    if (isChangingPassword.value) {
        cancelPasswordChange()
    } else {
        isChangingPassword.value = true
    }
}

const handleChangePassword = async () => {
    if (!isPasswordValid.value) return

    loading.value = true
    try {
        await userStore.changePassword(currentPassword.value, newPassword.value)
        cancelPasswordChange()
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Your password has been changed successfully',
            life: 3000
        })
    } catch (error: any) {
        passwordError.value = error.message || 'Failed to change password'
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error.message || 'Failed to change your password. Please try again.',
            life: 3000
        })
    } finally {
        loading.value = false
    }
}

const cancelPasswordChange = () => {
    isChangingPassword.value = false
    currentPassword.value = ''
    newPassword.value = ''
    confirmPassword.value = ''
    passwordError.value = ''
}
</script>
