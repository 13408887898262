<template>
    <div
v-if="hasBrands"
        ref="menuButton"
        :data-testid="brandData.channel + '-dropdown-button'"
        class="h-full max-w-52 flex text-xs font-semibold px-4 text-surface-500 hover:bg-surface-950/[.06] active:bg-brand-500/[.12] hover:cursor-pointer active:border-b-2 active:border-brand-500 focus:border-b-2 focus:border-brand-500 relative"
        :class="{
            'bg-brand-500/[.12] channel-outline': isActiveChannel || isMenuOpen,
        }" aria-haspopup="true" aria-controls="channel_overlay_menu"
        @click="setSelectedChannel($event, brandData.channel)">

        <div class="flex w-full gap-2 items-center">
            <!-- Channel and Brand -->
            <div id="left" class="flex flex-col w-full overflow-hidden">
                <span class="w-full">
                    {{ channelDisplayName }}
                </span>
                <span v-if="isActiveChannel" id="truncated" class="text-surface-800 font-semibold truncate">
                    {{ activeBrand?.name }}
                </span>
            </div>

            <!-- Dropdown Arrow -->
            <div v-if="hasMultipleBrands" id="right">
                <FaIcon v-show="isMenuOpen" icon="far fa-angle-up" class="h-3 w-3 self-center relative"></FaIcon>
                <FaIcon v-show="!isMenuOpen" icon="far fa-angle-down" class="h-3 w-3 self-center relative"></FaIcon>
            </div>
        </div>

    </div>

    <Menu
id="channel_overlay_menu" ref="brandMenu" :model="menuItems" :popup="true" :pt="{
        root: { class: ['max-h-[66vh] overflow-x-auto mt-1 rounded border-black-100[.1] drop-shadow text-surface-700 !p-0'] },
        item: { class: ['hover:background-surface-950[.06] active:bg-brand-500/[.12] hover:cursor-pointer'] },
        itemContent: { class: ['hover:none'] },
        itemLink: { class: ['!text-[13px] !px-2 !py-1.5 active:bg-brand-500/[.12]'] }
    }">
        <template #item="{ item, props }">
            <a
v-ripple v-tooltip.hover.top="{ value: item.name, showDelay: 1500 }" :href="item.url"
                :target="item.target" v-bind="props.action" :class="{
                    'bg-brand-500/[.12] !text-brand-500 is-active-brand': isActiveBrand(item.id)
                }" @click="switchBrand(item.id)">
                {{ item.name }}
            </a>
        </template>
    </Menu>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { type BrandData } from '@/interfaces/NavBar';
import type { UserBrandData } from '@/api/auth.api';
import { capitalizeFirstLetter } from '@/utils/helpers';
import type { HelpCenterChannel } from '@/interfaces/HelpCenter';

const props = defineProps<{
    activeBrand?: UserBrandData
    activeChannel: HelpCenterChannel
    brandData: BrandData
    selectedChannel?: HelpCenterChannel
}>()

const menuButton = ref()

const emit = defineEmits(['set-selected-channel', 'clear-selected-channel', 'switch-brand'])

interface MenuItem {
    name: string
    link: string
    channel: HelpCenterChannel
    id: string
}

const menuItems = computed<MenuItem[]>(() => {
    return props.brandData.brands.map((brand: UserBrandData) => {
        return {
            name: brand.name,
            link: `/to/brand/channel/${brand.channel}`,
            channel: brand.channel,
            id: brand.id
        }
    })
})

const brandMenu = ref()

const setSelectedChannel = (event: Event, channel: HelpCenterChannel) => {
    if (hasMultipleBrands.value) {
        brandMenu.value.toggle(event)
    }
    emit('set-selected-channel', channel, hasMultipleBrands.value)
}

const switchBrand = (brandId: string): void => {
    emit('switch-brand', brandId, props.brandData.channel)
}

const isActiveBrand = (brandId: string): boolean => {
    return props.activeBrand?.id === brandId ? true : false
}

const isActiveChannel = computed(() => {
    return props.activeChannel === props.brandData.channel ? true : false
})

const hasMultipleBrands = computed(() => {
    return props.brandData.brands.length > 1
})

const hasBrands = computed(() => {
    return props.brandData.brands.length > 0
})

const isMenuOpen = computed(() => {
    return props.brandData.channel === props.selectedChannel && brandMenu.value?.overlayVisible
})

const channelDisplayName = computed(() => {
    return capitalizeFirstLetter(props.brandData.channel);
})

</script>

<style lang="scss" scoped>
.channel-outline::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(3, 128, 255);
}
</style>
