<template>
    <Dialog
        :visible="visible"
        modal
        dismissable-mask
        :pt="{ header: { class: ['bg-surface-100 dark:bg-surface-800 !py-2 px-3'] } }"
        class="w-[600px]"
        @update:visible="handleVisibilityChange"
    >
        <template #header>
            <div class="flex justify-between items-center">
                <FaIcon :icon="['fas', 'video-plus']" class="mr-2" />
                <h3 class="text-surface-800 text-xl font-semibold leading-7">
                    {{ isEdit ? 'Edit video details' : 'Add new video' }}
                </h3>
                <Button icon="pi pi-times" severity="danger" text @click="handleClose" />
            </div>
        </template>
        <div class="flex flex-col p-12 gap-6">
            <div class="flex flex-col gap-1">
                <label class="form-label">Video url</label>
                <InputText
                    v-model="form.url"
                    placeholder="i.e. https://player.vimeo.com/video/123?h=abc&amp;app_id=1234"
                    class="w-full form-input"
                    :invalid="touched.url && errors.url"
                    @blur="handleBlur('url')"
                />
                <small v-if="touched.url && errors.url" class="text-red-500">URL is missing</small>
                <small v-else class="text-surface-500 text-xs mt-1">Paste embed url here</small>
            </div>

            <div class="flex flex-col gap-1">
                <label class="form-label">Title</label>
                <InputText
                    v-model="form.title"
                    placeholder="Add a title describing the video"
                    class="w-full form-input"
                    :invalid="touched.title && errors.title"
                    @blur="handleBlur('title')"
                />
                <small v-if="touched.title && errors.title" class="text-red-500">Title is missing</small>
            </div>

            <div class="flex flex-col gap-1">
                <label class="form-label">Description</label>
                <Textarea
                    v-model="form.description"
                    placeholder="Write a short description of what the video talks about."
                    class="w-full form-input"
                    :invalid="touched.description && errors.description"
                    rows="3"
                    @blur="handleBlur('description')"
                />
                <small v-if="touched.description && errors.description" class="text-red-500"
                    >Description is missing</small
                >
            </div>

            <div class="flex flex-col gap-1">
                <label class="form-label">Type</label>
                <Select
                    v-model="form.type"
                    :options="typeOptions"
                    placeholder="Select"
                    option-label="label"
                    class="form-input"
                    :invalid="touched.type && errors.type"
                    @blur="handleBlur('type')"
                >
                    <template #option="slotProps">
                        <div class="flex items-center gap-2">
                            <FaIcon :icon="['far', slotProps.option.icon]" class="text-surface-600" size="sm" />
                            <span>{{ slotProps.option.label }}</span>
                        </div>
                    </template>
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex items-center gap-2">
                            <FaIcon
                                :icon="['far', getIconForType(slotProps.value.value)]"
                                class="text-surface-600"
                                size="sm"
                            />
                            <span>{{ slotProps.value.label }}</span>
                        </div>
                        <span v-else>
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                </Select>
                <small v-if="touched.type && errors.type" class="text-red-500">Type is required</small>
            </div>

            <div class="flex flex-col gap-1">
                <label class="form-label">Channels</label>
                <MultiSelect
                    v-model="form.channels"
                    :options="channelOptions"
                    option-label="label"
                    option-value="value"
                    placeholder="Add"
                    display="chip"
                    class="w-full form-input"
                    filter
                    :pt="multiSelectPt"
                />
                <small v-if="allChannelsSelected" class="text-surface-500 text-xs mt-1"> All channels selected </small>
            </div>

            <div class="flex flex-col gap-1">
                <label class="form-label">Features</label>
                <MultiSelect
                    v-model="form.features"
                    :options="featureOptions"
                    option-label="label"
                    option-value="value"
                    placeholder="Add"
                    display="chip"
                    filter
                    class="w-full form-input"
                    :pt="multiSelectPt"
                />
                <small v-if="allFeaturesSelected" class="text-surface-500 text-xs mt-1"> All features selected </small>
            </div>

            <div class="flex flex-col gap-1">
                <label class="form-label">Publish date</label>
                <DatePicker
                    v-model="form.publishTime"
                    class="form-input"
                    show-icon
                    fluid
                    icon-display="input"
                    show-on-focus
                    :max-date="dayjs().toDate()"
                    :manual-input="false"
                    show-week
                />
            </div>
        </div>

        <template #footer>
            <div class="flex justify-between w-full pt-4 border-t border-surface-200 dark:border-surface-700">
                <div>
                    <Button v-if="isEdit" label="Delete" severity="danger" text @click="handleDelete" />
                </div>
                <div class="flex gap-2">
                    <Button
                        :label="getCloseButtonLabel"
                        rounded
                        size="small"
                        :pt="{
                            root: {
                                class: [
                                    'bg-primary-200 !text-primary-500 border-none focus:ring-0 hover:!bg-primary-300 focus:!bg-primary-300'
                                ]
                            }
                        }"
                        @click="handleClose"
                    />
                    <Button label="Save" rounded size="small" :disabled="!isValid" @click="handleSave" />
                </div>
            </div>
        </template>
    </Dialog>

    <Dialog v-model:visible="showConfirmDialog" header="Discard unsaved changed?" :modal="true" class="w-[400px]">
        <template #footer>
            <div class="flex gap-2 justify-end">
                <Button label="Cancel" text size="small" @click="showConfirmDialog = false" />
                <Button label="Discard" severity="danger" rounded size="small" @click="confirmDiscard" />
            </div>
        </template>
    </Dialog>

    <Dialog v-model:visible="showDeleteDialog" header="Delete video permanently?" :modal="true" class="w-[400px]">
        <p>This will remove the video from the library and the video file will not be recoverable.</p>
        <template #footer>
            <div class="flex gap-2 justify-end">
                <Button label="Cancel" text size="small" @click="showDeleteDialog = false" />
                <Button label="Delete" severity="danger" rounded size="small" @click="confirmDelete" />
            </div>
        </template>
    </Dialog>
</template>

<script lang="ts" setup>
import { ref, computed, watch, type PropType, inject } from 'vue'
import {
    HelpCenterChannel,
    HelpCenterFeature,
    type HelpCenterVideo,
    type PostHelpCenterUIPayload,
    type PutHelpCenterUIPayload
} from '@/interfaces/HelpCenter'
import { HelpCenterType } from '@/interfaces/HelpCenter'
import { useHelpCenterStore } from '@/stores/helpCenter'
import { typeOptions } from '../constants'
import { dayjsPlugin } from '@/plugins/dayjs'
import { useToast } from 'primevue/usetoast'
import { isArrayEqual } from '@/utils/helpers'

const dayjs: typeof dayjsPlugin = inject('dayjs') || dayjsPlugin
const toast = useToast()
const props = defineProps({
    visible: {
        type: Boolean,
        required: true
    },
    isEdit: {
        type: Boolean,
        required: false
    },
    videoData: {
        type: Object as PropType<HelpCenterVideo | null>,
        required: false,
        default: null
    }
})

const helpCenterStore = useHelpCenterStore()

const emit = defineEmits(['update:visible', 'save', 'delete', 'refetch'])

// Options for dropdowns

const channelOptions = [
    { label: 'Call', value: HelpCenterChannel.Call },
    { label: 'Document', value: HelpCenterChannel.Document },
    { label: 'Email', value: HelpCenterChannel.Email },
    { label: 'Message', value: HelpCenterChannel.Message },
    { label: 'Social', value: HelpCenterChannel.Social },
    { label: 'Web', value: HelpCenterChannel.Web }
]
const featureOptions = [
    { label: 'Assets', value: HelpCenterFeature.Assets },
    { label: 'Campaigns', value: HelpCenterFeature.Campaigns },
    { label: 'Remediation', value: HelpCenterFeature.Remediation },
    { label: 'Reporting', value: HelpCenterFeature.Reporting },
    { label: 'Rules', value: HelpCenterFeature.Rules },
    { label: 'Traffic Sources', value: HelpCenterFeature.TrafficSources },
    { label: 'User Management', value: HelpCenterFeature.UserManagement },
    { label: 'Vendor Portal', value: HelpCenterFeature.VendorPortal },
    { label: 'Other', value: HelpCenterFeature.Other }
]

// Add initial form state
const initialFormState: {
    url: string
    title: string
    description: string
    type: any
    channels: HelpCenterChannel[]
    features: HelpCenterFeature[]
    publishTime: Date
} = {
    url: '',
    title: '',
    description: '',
    type: typeOptions.find((opt) => opt.value === HelpCenterType.TuesdayTutorial),
    channels: [],
    features: [],
    publishTime: dayjs().toDate()
}

// Update form ref to use initialFormState
const form = ref({ ...initialFormState })

const errors = ref({
    url: false,
    title: false,
    description: false,
    type: false
})

const touched = ref({
    url: false,
    title: false,
    description: false,
    type: false
})

const showConfirmDialog = ref(false)
const showDeleteDialog = ref(false)
const hasChanges = ref(false)

const isValid = computed(() => {
    return form.value.url && form.value.title && form.value.description && form.value.type && form.value.publishTime
})

const handleVisibilityChange = (value: boolean) => {
    if (!value && hasChanges.value) {
        showConfirmDialog.value = true
    } else if (!value) {
        resetFormState()
        emit('update:visible', value)
    } else {
        emit('update:visible', value)
    }
}

const handleClose = () => {
    if (hasChanges.value) {
        showConfirmDialog.value = true
    } else {
        resetFormState()
        emit('update:visible', false)
    }
}

const handleSave = async () => {
    // Mark all fields as touched
    Object.keys(touched.value).forEach((key) => {
        touched.value[key as keyof typeof touched.value] = true
    })

    // Validate all fields
    errors.value = {
        url: !form.value.url,
        title: !form.value.title,
        description: !form.value.description,
        type: !form.value.type
    }

    if (isValid.value) {
        try {
            const payload: PostHelpCenterUIPayload = {
                url: form.value.url,
                title: form.value.title,
                description: form.value.description,
                type: form.value.type.value,
                channels: form.value.channels,
                features: form.value.features,
                publishTime: dayjs(form.value.publishTime).toISOString()
            }

            if (props.isEdit && props.videoData?.id) {
                await helpCenterStore.updateVideo(props.videoData.id, payload as PutHelpCenterUIPayload)
                toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Video updated successfully',
                    life: 3000
                })
            } else {
                await helpCenterStore.createVideo(payload as PostHelpCenterUIPayload)
                toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Video created successfully',
                    life: 3000
                })
            }
            helpCenterStore.closeVideoFormDialog()
            resetFormState()
            emit('refetch')
            emit('update:visible', false)
        } catch (error) {
            console.error('Failed to save video:', error)
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: props.isEdit ? 'Failed to update video' : 'Failed to create video',
                life: 3000
            })
        }
    }
}

const handleDelete = () => {
    showDeleteDialog.value = true
}

const confirmDiscard = () => {
    showConfirmDialog.value = false
    resetFormState()
    emit('update:visible', false)
}

const confirmDelete = async () => {
    showDeleteDialog.value = false
    if (props.videoData?.id) {
        try {
            await helpCenterStore.deleteVideo(props.videoData.id)
            toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Video deleted successfully',
                life: 3000
            })
            emit('update:visible', false)
        } catch (error) {
            console.error('Failed to delete video:', error)
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to delete video',
                life: 3000
            })
        }
    }
}

const allChannelsSelected = computed(() => {
    return form.value.channels.length === channelOptions.length
})

const allFeaturesSelected = computed(() => {
    return form.value.features.length === featureOptions.length
})

const multiSelectPt = computed(() => ({
    label: { class: ['!whitespace-normal'] },
    option: { class: ['text-sm !px-4 !py-2 mx-2 my-0.5 !rounded-sm'] },
    input: { class: ['!w-2 !h-2'] },
    chipItem: { class: ['mr-1'] },
    pcchip: { root: ['!p-1 gap-1 my-0.5'], label: ['!m-0 !mx-1'] },
    pcoptioncheckbox: { root: { class: ['!h-4 !w-4'] }, box: { class: ['!w-4 !h-4 !rounded-sm'] } },
    pcheadercheckbox: { root: { class: ['!h-4 !w-4'] }, box: { class: ['!w-4 !h-4 !rounded-sm'] } },
    pcfilter: { root: { class: ['!p-1 !pl-8'] } },
    pcfiltericoncontainer: { root: { class: ['!left-3'], style: 'right: unset !important' } }
}))

const getIconForType = (type: string) => {
    const option = typeOptions.find((opt) => opt.value === type)
    return option?.icon || 'question'
}

const handleBlur = (field: keyof typeof errors.value) => {
    touched.value[field] = true
    validateField(field)
}

const validateField = (field: keyof typeof errors.value) => {
    if (!touched.value[field]) return

    switch (field) {
        case 'url':
            errors.value.url = !form.value.url
            break
        case 'title':
            errors.value.title = !form.value.title
            break
        case 'description':
            errors.value.description = !form.value.description
            break
        case 'type':
            errors.value.type = !form.value.type
            break
    }
}

const getCloseButtonLabel = computed(() => {
    if (!props.isEdit || hasChanges.value) {
        return 'Discard'
    }
    return 'Close'
})

const resetFormState = () => {
    form.value = { ...initialFormState }
    errors.value = {
        url: false,
        title: false,
        description: false,
        type: false
    }
    touched.value = {
        url: false,
        title: false,
        description: false,
        type: false
    }
    hasChanges.value = false
}

watch(
    () => props.videoData,
    (newData) => {
        if (newData) {
            form.value = {
                url: newData.url || '',
                title: newData.title || '',
                description: newData.description || '',
                type: typeOptions.find((opt) => opt.value === newData.type),
                channels: newData.channels || [],
                features: newData.features || [],
                publishTime: newData.publishTime ? dayjs(newData.publishTime).toDate() : dayjs().toDate()
            }
        } else {
            form.value = { ...initialFormState }
        }
    },
    { immediate: true }
)

watch(
    form,
    () => {
        if (props.videoData) {
            // For edit mode, compare against the original video data
            hasChanges.value =
                form.value.url !== props.videoData.url ||
                form.value.title !== props.videoData.title ||
                form.value.description !== props.videoData.description ||
                form.value.type.value !== props.videoData.type ||
                !isArrayEqual(form.value.channels, props.videoData.channels || []) ||
                !isArrayEqual(form.value.features, props.videoData.features || []) ||
                !dayjs(form.value.publishTime).isSame(dayjs(props.videoData.publishTime))
        } else {
            // For create mode, compare against initialFormState
            hasChanges.value =
                form.value.url !== initialFormState.url ||
                form.value.title !== initialFormState.title ||
                form.value.description !== initialFormState.description ||
                form.value.type.value !== initialFormState.type.value ||
                form.value.channels.length > 0 ||
                form.value.features.length > 0 ||
                !dayjs(form.value.publishTime).isSame(dayjs(initialFormState.publishTime))
        }
    },
    { deep: true }
)
</script>
<style scoped>
.form-label {
    @apply text-xs font-normal leading-none text-surface-500;
}
.form-input {
    @apply text-sm font-normal leading-tight text-surface-700;
}
.remove-right {
    right: unset !important;
}
</style>
