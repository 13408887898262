<template>
    <Button
        :data-testid="name + '-filter'"
        text
        class="text-surface-700 !border !border-surface-950/[.12] hover:bg-surface-950/[.06] focus:bg-surface-950[.03] focus:ring-brand-300 active:bg-brand-500[.12] active:text-brand-500 rounded-lg !px-2 !py-1 flex items-center text-nowrap h-6 m-1 max-w-60 overflow-hidden"
        :class="{
            'not:focus:bg-primary-500/[.12] not:active:bg-primary-500/[.12] !bg-primary-500/[.12] border !border-brand-500 !text-brand-500':
                filterState.isActive
        }"
        @click="toggleShowFilter"
    >
    
        <slot name="icon" :variant="darkBlueText"></slot>
        <span class="text-sm self-center px-1" :class="darkBlueText">{{ label }}{{ addColon }}</span>

        <div class="truncate" :class="darkBlueText">
            <slot name="value"></slot>
        </div>

        <FaIcon
            :icon="isPopoverVisible ? 'fas fa-caret-up' : 'fas fa-caret-down'"
            :class="darkBlueText"
            class="ml-1"
        ></FaIcon>
    </Button>

    <Popover
        ref="mainPopover"
        class="bg-surface-100 rounded-2xl shadow !border border-surface-950/10 after:content-none"
        :pt="{
            root: { class: ['min-w-[263px] w-min'] },
            content: { class: ['flex-wrap'] }
        }"
    >
        <div v-if="enableClearFilter" class="flex w-full gap-2">
            <Button
                data-testid="clear-filter"
                text
                class="border !border-surface-950/10 text-surface-700 h-8 w-full"
                @click="emit('clear-filter')"
            >
                <FaIcon icon="far fa-xmark" class="text-xs mx-1"></FaIcon>
                <span class="text-sm self-center px-1">Clear</span>
            </Button>
        </div>
        <slot name="content" :main-popover="mainPopover"> </slot>
        <slot name="footer"></slot>
    </Popover>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: false
    },
    isActive: {
        type: Boolean,
        required: true
    },
    enableClearFilter: {
        type: Boolean,
        required: false
    },
    hasOpenEvent: {
        type: Boolean,
        required: false
    }
})

const emit = defineEmits(['clear-filter', 'on-open-filter'])

const filterState = ref({
    isActive: props.isActive,
    isPopoverVisible: false
})

watch(
    () => props.isActive,
    (isActive: boolean) => {
        filterState.value.isActive = isActive
    }
)

const mainPopover = ref()

function toggleShowFilter(event: Event) {
    mainPopover.value.toggle(event)
    if (props.hasOpenEvent && mainPopover.value.visible) {
        emit('on-open-filter')
    }
}

const isPopoverVisible = computed(() => {
    return mainPopover.value && mainPopover.value.visible
})

const darkBlueText = computed(() => {
    return filterState.value.isActive ? '!text-brand-500' : ''
})

const addColon = computed(() => {
    return filterState.value.isActive ? ':' : ''
})
</script>
