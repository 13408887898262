<template>
    <TableFilter
        :name="'type'"
        :label="'Type'"
        :is-active="isFilterActive"
        :enable-clear-filter="true"
        @clear-filter="onRemoveFilter"
    >
        <template #icon="{ variant }">
            <FaIcon icon="far fa-list" :class="variant"></FaIcon>
        </template>

        <template #value>
            <div
                v-if="isFilterActive"
                class="text-sm m-w-40 text-ellipsis overflow-hidden"
                data-testid="type-filter-display"
            >
                {{ typesInLabel }}
            </div>
        </template>

        <template #content>
            <div :key="componentKey" class="grid gap-2 my-4 w-full">
                <div v-for="type in typeOptions" :key="type.value" class="flex align-center w-full text-sm py-1 px-4">
                    <Checkbox
                        v-model="selectedTypes"
                        :input-id="type.value"
                        :name="type.value"
                        :value="type.value"
                        :pt="{
                            root: { class: ['m-auto !h-4 !w-4 !py-0 '] },
                            box: { class: ['!h-4 !w-4 !rounded'] }
                        }"
                    />
                    <label :for="type.value" class="flex w-full hover:cursor-pointer">
                        <span class="ml-4">{{ type.label }}</span>
                    </label>
                </div>
            </div>
        </template>
    </TableFilter>
</template>

<script lang="ts" setup>
import { computed, ref, watch, type PropType } from 'vue'
import TableFilter from '@/components/dataTable/tableFilter/TableFilter.vue'
import { camelCaseToTitleCase } from '@/utils/helpers'
import { HelpCenterType } from '@/interfaces/HelpCenter'

export interface TypeOption {
    value: HelpCenterType
    label: string
    isSelected: boolean
}

const props = defineProps({
    types: {
        type: Array as PropType<HelpCenterType[]>,
        required: false,
        default: undefined
    }
})

const emit = defineEmits(['update-type-filter', 'remove-type-filter'])

const typeOptions = ref<TypeOption[]>(
    Object.keys(HelpCenterType).map((key) => ({
        value: HelpCenterType[key as keyof typeof HelpCenterType],
        label: camelCaseToTitleCase(key),
        isSelected: false
    }))
)

const selectedTypes = ref<string[]>([])

watch(
    () => props.types,
    (payload: HelpCenterType[] | undefined) => {
        setTimeout(() => {
            if (payload) {
                const isDifferent =
                    payload.length !== selectedTypes.value.length ||
                    payload.some((type, index) => type !== selectedTypes.value[index])
                if (isDifferent) {
                    selectedTypes.value = payload
                }
            } else if (selectedTypes.value.length > 0) {
                selectedTypes.value = []
            }
        }, 0)
    }
)

const componentKey = ref(0)

watch(
    () => selectedTypes.value,
    (types: string[]) => {
        componentKey.value += 1
        setTimeout(() => {
            emit('update-type-filter', { types: types.length > 0 ? types : undefined })
        }, 0)
    }
)

const onRemoveFilter = () => {
    emit('remove-type-filter')
}

const isFilterActive = computed(() => {
    return selectedTypes.value.length > 0
})

const typesInLabel = computed(() => {
    return selectedTypes.value.map((type) => camelCaseToTitleCase(type)).join(', ')
})
</script>
