<template>
    <Toast></Toast>
    <DataTable
        :value="state.users"
        :rows="userManagementFilters.size"
        table-style="width: 100%"
        scrollable
        resizable-columns
        column-resize-mode="fit"
        sort-mode="single"
        :sort-field="userManagementFilters.orderBy"
        :sort-order="userManagementFilters.sortOrder"
        lazy
        :loading="state.loading"
        :pt="{
            root: { class: ['text-sm relative flex flex-col tags-table-root'] },
            tableContainer: { class: ['flex-1'] },
            header: { class: ['text-xl !p-0'] },
            wrapper: { class: ['flex-1'] },
            bodyrow: { class: ['hover:bg-neutral-600 !h-[50px]'] },
            footer: { class: ['!p-0 !font-normal'] }
        }"
        @sort="onSort"
        @column-resize-end="endColumnResize"
        @mousedown="trackMouseDown"
    >
        <template #header>
            <UserManagementHeader
                :is-export-loading="isExportLoading"
                @get-users-summary-info="getUsersSummary"
                @export-users="exportUsers"
            />
        </template>

        <Column
            field="fullName"
            header="User"
            :sortable="!resizing"
            :pt="{
                ...columnPt,
                bodyCell: { class: [...columnPt.bodyCell.class, 'w-56 min-w-56 max-w-56'] }
            }"
        >
            <template #body="{ data }">
                <div class="flex justify-between">
                    <div>
                        <span
                            class="user-name cursor-pointer text-sm leading-tight font-medium"
                            @click="copyUserEmail(data)"
                        >
                            {{ data.fullName }}
                        </span>
                        <br />
                        <span class="text-xs leading-none font-normal text-surface-700">{{ data.emailAddress }}</span>
                    </div>
                    <div class="user-controls">
                        <Button class="focus:outline-none !py-2" text rounded size="small" @click="copyUserEmail(data)">
                            <FaIcon icon="far fa-copy" class="text-surface-800"></FaIcon>
                        </Button>
                    </div>
                </div>
            </template>
        </Column>

        <Column
            field="primaryRole"
            header="Role"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <div class="flex flex-col items-start">
                    <span>{{ userRoleLabels[data.primaryRole as keyof typeof userRoleLabels] }}</span>
                    <span v-if="isUserVendor(data.primaryRole)" class="text-xs text-surface-700 leading-none">
                        Limited access
                    </span>
                </div>
            </template>
        </Column>

        <Column
            field="active"
            header="Status"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <SelectUserStatus v-if="!isInactiveVendor(data) && canEditUserStatus" :user="data" />
                <div
                    v-else-if="isInactiveVendor(data) && canEditUserStatus"
                    v-tooltip.bottom="'Access provided by sending asset remediation.'"
                    class="gap-2 flex items-center pl-2 py-1"
                >
                    <span class="">Inactive</span>
                    <FaIcon icon="far fa-circle-info" class="standard-inactive-icon text-surface-800 h-[12px]"></FaIcon>
                    <FaIcon icon="fas fa-circle-info" class="hover-inactive-icon h-[12px]"></FaIcon>
                </div>
                <div v-else class="gap-2 flex items-center pl-2 py-1">
                    <span class="">{{ data.active ? 'Active' : 'Inactive' }}</span>
                </div>
            </template>
        </Column>

        <Column
            field="deactivatedAt"
            header="Inactive since"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <span v-if="data.deactivatedAt && dayjs(data.deactivatedAt).isAfter(dayjs('2010-01-01'))">{{
                    dayjs(data.deactivatedAt).format('ll')
                }}</span>
                <span v-else>-</span>
            </template>
        </Column>

        <Column
            field="lastLoginAt"
            header="Last login"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <span v-if="data.lastLoginAt && dayjs(data.lastLoginAt).isAfter(dayjs('2010-01-01'))">{{
                    dayjs(data.lastLoginAt).format('ll')
                }}</span>
                <span v-else>-</span>
            </template>
        </Column>

        <Column
            field="createdAt"
            header="Created"
            :sortable="!resizing"
            :pt="{ ...columnPt, bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] } }"
        >
            <template #body="{ data }">
                <span>{{ dayjs(data.createdAt).format('ll') }}</span>
            </template>
        </Column>

        <template #footer>
            <Paginator
                :first="currentFirst"
                :rows="userManagementFilters.size"
                :total-records="state.pageInfo?.totalCount"
                template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink JumpToPageDropdown"
                current-page-report-template="Showing {first} to {last} of {totalRecords}"
                :pt="{
                    root: { class: ['!py-1'] },
                    pcJumpToPageDropdown: {
                        class: ['h-8'],
                        label: { class: ['!p-1.5'] },
                        dropdown: { class: ['w-8'] }
                    },
                    first: { class: ['h-8 !m-0'] },
                    prev: { class: ['h-8 !m-0'] },
                    next: { class: ['h-8 !m-0'] },
                    last: { class: ['h-8 !m-0'] }
                }"
                @page="onPage"
            />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type DataTableSortEvent } from 'primevue/datatable'
import { type PageState } from 'primevue/paginator'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserManagementStore } from '@/stores/userManagementStore'
import UserManagementHeader from './UserManagementHeader.vue'
import SelectUserStatus from './SelectUserStatus.vue'
import { dayjsPlugin } from '@/plugins/dayjs'
import {
    type CompanyUser,
    UserManagementOrderByOptions,
    type GetUserManagementUIParams
} from '@/interfaces/UserManagement'
import { useToast } from 'primevue/usetoast'
import { userRoleLabels, UserRoles } from '@/constants'
import { stringToBool, validateEnum } from '@/utils/helpers'
import { useUserStore } from '@/stores/userStore'
import { useRouteValidation } from '@/composables/useRouteValidation'
import { sortOrderHelpers, type SortOrderNumber, type SortOrderString } from '@/interfaces/Sorting'

const dayjs = dayjsPlugin

const userStore = useUserStore()
const { isClientAdmin, isStaff, isSuperUser } = storeToRefs(userStore)

const userManagementStore = useUserManagementStore()
const { state, userManagementFilters, currentFirst } = storeToRefs(userManagementStore)

const columnPt = {
    headerTitle: { class: ['truncate whitespace-nowrap font-normal'] },
    bodyCell: { class: ['whitespace-nowrap h-12 !py-0'] }
}

const toast = useToast()
const route = useRoute()
const companyId = route.params.companyId as string
const isExportLoading = ref<boolean>(false)

const resizing = ref(false)

function onValidRoute() {
    loadLazyData()
}

useRouteValidation<GetUserManagementUIParams>({
    decodeQueryToState,
    constructExpectedQuery,
    onValidRoute,
    filters: userManagementFilters
})

function decodeQueryToState(query: any) {
    const {
        page,
        orderBy,
        sortOrder,
        first,
        search,
        role,
        active,
        inactive,
        deactivatedAtBefore,
        deactivatedAtAfter,
        lastLoginBefore,
        lastLoginAfter,
        createdAtBefore,
        createdAtAfter
    } = query

    const filters: Partial<GetUserManagementUIParams> = {
        page: validatePage(page),
        orderBy: validateOrderBy(orderBy),
        sortOrder: validateSortOrder(sortOrder),
        first: validateFirstPage(first, page, userManagementFilters.value.size)
    }

    if (search) {
        filters.search = search
    }

    if (validateEnum(role, UserRoles)) {
        filters.role = role.includes(',') ? role.split(',') : [role]
    } else {
        userManagementStore.removeUserFilters(['role'])
    }

    if (active) {
        filters.active = stringToBool(active)
    } else {
        userManagementStore.removeUserFilters(['active'])
    }

    if (inactive) {
        filters.inactive = stringToBool(inactive)
    } else {
        userManagementStore.removeUserFilters(['inactive'])
    }

    if (validateDate(createdAtBefore) && validateDate(createdAtAfter)) {
        filters.createdAtBefore = dayjs(createdAtBefore).format('YYYY-MM-DD')
        filters.createdAtAfter = dayjs(createdAtAfter).format('YYYY-MM-DD')
    } else {
        userManagementStore.removeUserFilters(['createdAtBefore', 'createdAtAfter'])
    }

    if (validateDate(lastLoginBefore) && validateDate(lastLoginAfter)) {
        ;(filters.lastLoginBefore = dayjs(lastLoginBefore).format('YYYY-MM-DD')),
            (filters.lastLoginAfter = dayjs(lastLoginAfter).format('YYYY-MM-DD'))
    } else {
        userManagementStore.removeUserFilters(['lastLoginBefore', 'lastLoginAfter'])
    }

    if (validateDate(deactivatedAtBefore) && validateDate(deactivatedAtAfter)) {
        ;(filters.deactivatedAtBefore = dayjs(deactivatedAtBefore).format('YYYY-MM-DD')),
            (filters.deactivatedAtAfter = dayjs(deactivatedAtAfter).format('YYYY-MM-DD'))
    } else {
        userManagementStore.removeUserFilters(['deactivatedAtBefore', 'deactivatedAtAfter'])
    }

    userManagementStore.updateUserFilters(filters)
}

function validatePage(page: any) {
    const pageNumber = parseInt(page)
    return isNaN(pageNumber) || pageNumber < 1 ? userManagementFilters.value.page : pageNumber
}

function validateOrderBy(orderBy: string | undefined): UserManagementOrderByOptions {
    if (!orderBy || typeof orderBy !== 'string') {
        return UserManagementOrderByOptions.LastLoginAtAsc
    }
    const validOrderBy = validateEnum(orderBy, UserManagementOrderByOptions)
    return validOrderBy ? (orderBy as UserManagementOrderByOptions) : UserManagementOrderByOptions.LastLoginAtAsc
}

function validateSortOrder(sortOrder: SortOrderString | undefined): SortOrderNumber {
    return sortOrderHelpers.fromQuery(sortOrder ?? 'dsc')
}

function validateDate(dateString: string): string | null {
    if (!dateString) return null
    const date = dayjs(dateString)
    return date.isValid() ? date.format('YYYY-MM-DD') : null
}

function validateFirstPage(first: number, page: number, size: number): number {
    if (first) return first
    return (page - 1) * size
}

function constructExpectedQuery() {
    return constructUserManagementQuery()
}

function constructUserManagementQuery() {
    const updatedQueryParams: any = {
        orderBy: userManagementFilters.value.orderBy,
        sortOrder: sortOrderHelpers.toQuery(userManagementFilters.value.sortOrder as SortOrderNumber),
        page: userManagementFilters.value.page?.toString()
    }

    if (userManagementFilters.value.search) {
        updatedQueryParams.search = userManagementFilters.value.search
    }
    if (userManagementFilters.value.createdAtBefore) {
        updatedQueryParams.createdAtBefore = userManagementFilters.value.createdAtBefore
    }
    if (userManagementFilters.value.createdAtAfter) {
        updatedQueryParams.createdAtAfter = userManagementFilters.value.createdAtAfter
    }
    if (userManagementFilters.value.lastLoginBefore) {
        updatedQueryParams.lastLoginBefore = userManagementFilters.value.lastLoginBefore
    }
    if (userManagementFilters.value.lastLoginAfter) {
        updatedQueryParams.lastLoginAfter = userManagementFilters.value.lastLoginAfter
    }
    if (userManagementFilters.value.deactivatedAtBefore) {
        updatedQueryParams.deactivatedAtBefore = userManagementFilters.value.deactivatedAtBefore
    }
    if (userManagementFilters.value.deactivatedAtAfter) {
        updatedQueryParams.deactivatedAtAfter = userManagementFilters.value.deactivatedAtAfter
    }
    if (userManagementFilters.value.role) {
        updatedQueryParams.role = userManagementFilters.value.role.join(',')
    }
    if (userManagementFilters.value.active) {
        updatedQueryParams.active = userManagementFilters.value.active.toString()
    }
    if (userManagementFilters.value.inactive) {
        updatedQueryParams.inactive = userManagementFilters.value.inactive.toString()
    }

    return updatedQueryParams
}

const loadLazyData = async () => {
    userManagementStore.updateUserFilters(constructUserManagementUIParams())
    try {
        await userManagementStore.fetchUsers(companyId)
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to fetch users.`,
            life: 3000
        })
    }
}

const getUsersSummary = async () => {
    const filters = constructUserManagementUIParams()
    // remove role filter
    delete filters.role
    try {
        await userManagementStore.fetchUsersSummary(companyId, filters)
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to fetch users summary info.`,
            life: 3000
        })
    }
}

function constructUserManagementUIParams(): GetUserManagementUIParams {
    const { page, size, orderBy, sortOrder } = userManagementFilters.value

    const params: GetUserManagementUIParams = {
        page,
        size,
        orderBy,
        sortOrder
    }
    if (userManagementFilters.value.search) {
        params.search = userManagementFilters.value.search
    }
    if (userManagementFilters.value.createdAtBefore) {
        params.createdAtBefore = userManagementFilters.value.createdAtBefore
    }
    if (userManagementFilters.value.createdAtAfter) {
        params.createdAtAfter = userManagementFilters.value.createdAtAfter
    }
    if (userManagementFilters.value.lastLoginBefore) {
        params.lastLoginBefore = userManagementFilters.value.lastLoginBefore
    }
    if (userManagementFilters.value.lastLoginAfter) {
        params.lastLoginAfter = userManagementFilters.value.lastLoginAfter
    }
    if (userManagementFilters.value.deactivatedAtBefore) {
        params.deactivatedAtBefore = userManagementFilters.value.deactivatedAtBefore
    }
    if (userManagementFilters.value.deactivatedAtAfter) {
        params.deactivatedAtAfter = userManagementFilters.value.deactivatedAtAfter
    }
    if (userManagementFilters.value.role) {
        params.role = userManagementFilters.value.role
    }
    if (userManagementFilters.value.active) {
        params.active = userManagementFilters.value.active
    }
    if (userManagementFilters.value.inactive) {
        params.inactive = userManagementFilters.value.inactive
    }
    return params
}

const onPage = async (event: PageState) => {
    userManagementStore.updateUserFilters({
        page: event.page + 1,
        size: event.rows
    })
}

const onSort = async (sortEvent: DataTableSortEvent): Promise<void> => {
    const orderByField = sortEvent.sortField as UserManagementOrderByOptions
    const orderBy = validateEnum(orderByField, UserManagementOrderByOptions)
        ? (orderByField as UserManagementOrderByOptions)
        : undefined

    if (orderBy) {
        userManagementStore.updateUserFilters({
            orderBy,
            sortOrder: sortEvent.sortOrder === 1 ? 1 : -1,
            page: 1,
            first: 0
        })
    } else {
        userManagementStore.removeUserFilters(['orderBy', 'sortOrder'])
    }
}

const trackMouseDown = (event: MouseEvent): void => {
    if (event?.target) {
        const target = event.target as HTMLElement
        if (target.getAttribute('data-pc-section') === 'columnresizer') {
            resizing.value = true
        }
    }
}

const endColumnResize = () => {
    setTimeout(() => {
        resizing.value = false
    }, 300)
}

const copyUserEmail = (data: CompanyUser) => {
    navigator.clipboard.writeText(data.emailAddress)
    toast.add({
        severity: 'success',
        summary: 'Copied',
        detail: `Email copied to clipboard.`,
        life: 3000
    })
}

const isUserVendor = (userRole: string) => userRole.toLowerCase() === UserRoles.Vendor

const isInactiveVendor = (user: CompanyUser) => isUserVendor(user.primaryRole) && user.active === false

const exportUsers = async (exportName: string): Promise<void> => {
    const params = constructUserManagementUIParams()
    params.size = state.value?.pageInfo?.totalCount || 0
    isExportLoading.value = true
    try {
        await userManagementStore.exportUsers(companyId, exportName)
        isExportLoading.value = false
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Users exported successfully',
            life: 3000
        })
    } catch (error) {
        isExportLoading.value = false
        if (error instanceof Error) {
            if (error.name === 'AbortError') {
                // Do nothing for AbortError (user cancelled)
                return
            }
            // For other errors, show an error toast
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to export users',
                life: 3000
            })
        }
    }
}

const canEditUserStatus = computed(() => {
    return isClientAdmin.value || isStaff.value || isSuperUser.value
})
</script>
<style lang="scss" scoped>
tr[data-pc-section='bodyrow'] {
    .user-controls {
        display: none;
    }

    .hover-inactive-icon {
        display: none;
    }

    &:hover {
        .user-name {
            color: rgb(var(--primary-500));
        }

        .user-controls {
            display: flex;
        }

        .standard-inactive-icon {
            display: none;
        }

        .hover-inactive-icon {
            display: inline-block;
        }
    }

    &[data-p-highlight='true'] {
        background-color: rgba(var(--primary-500) / 0.12);

        td {
            border-bottom: 1px solid rgba(var(--surface-950) / 0.1);
        }
    }
}

.tags-table-root {
    height: calc(100vh - 40px);
}
</style>
