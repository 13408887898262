// src/router/index.ts for the global SPA
import { createRouter, createWebHistory } from 'vue-router'
import { tagsRoutes } from '@/views/TagsView/router'
import { userManagementRoutes } from '@/views/UserManagementPage/router'
import TagsApp from '@/views/TagsView/App.vue'
import UserManagementApp from '@/views/UserManagementPage/App.vue'
import { loginPageRoutes } from '@/views/LoginPage/router'
import LoginPageApp from '@/views/LoginPage/App.vue'
import { helpCenterViewRoutes } from '@/views/HelpCenterView/router'
import HelpCenterViewApp from '@/views/HelpCenterView/App.vue'
import { navBarRoutes } from '@/views/NavBar/router'
import NavBarApp from '@/views/NavBar/App.vue'
import { accountPageRoutes } from '@/views/AccountPage/router'
import AccountPageApp from '@/views/AccountPage/App.vue'
import { aboutPageRoutes } from '@/views/AboutPage/router'
import AboutPageApp from '@/views/AboutPage/App.vue'

const routes = [
    {
        path: '/',
        redirect: '/tags'
    },
    {
        path: '/tags',
        component: TagsApp,
        children: tagsRoutes,
        redirect: '/brand/2b8ff207-0ceb-43a4-854c-d3d1aa39123c/tags',
        meta: { requiresAuth: true }
    },
    {
        path: '/user-management',
        component: UserManagementApp,
        children: userManagementRoutes,
        redirect: '/company/cfc6b710-7811-4aa8-803b-93f644a126fb/user-management',
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        component: LoginPageApp,
        children: loginPageRoutes,
        meta: { requiresAuth: false }
    },
    {
        path: '/help-center',
        component: HelpCenterViewApp,
        children: helpCenterViewRoutes,
        redirect: '/company/z8a3EHgRSqiAO5P2RKEm-w/help-center',
        meta: { requiresAuth: true }
    },
    {
        path: '/:pathMatch(.*)*',
        component: NavBarApp,
        children: navBarRoutes
    },
    {
        path: '/account',
        component: AccountPageApp,
        children: accountPageRoutes,
        meta: { requiresAuth: true }
    },
    {
        path: '/about-page',
        component: AboutPageApp,
        children: aboutPageRoutes,
        meta: { requiresAuth: true }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router
