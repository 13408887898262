<template>
    <header class="bg-gray-100 border-b border-[#030b12]/10">
        <div class="max-w-[1980px] mx-auto">
            <div class="flex items-center justify-between px-6 py-2 h-14">
                <div class="flex items-center gap-2">
                    <FaIcon :icon="['far', 'circle-question']" :class="{ 'text-brand-500': isVideoLibraryActive }" />
                    <h1 class="text-surface-800 text-xl font-semibold leading-7">
                        <router-link :to="`/company/${companyId}/help-center`">
                            <span :class="{ 'text-brand-500': isVideoLibraryActive }"> Help Center </span>
                        </router-link>
                        {{ isVideoLibraryActive ? '/ Video Library' : '' }}
                    </h1>
                </div>
                <div class="flex gap-2">
                    <Button
                        ref="supportButton"
                        :class="{ '!bg-primary-500/[.12]': showSupportForm }"
                        :pt="{ icon: { class: ['!mr-0'] } }"
                        label="Request support"
                        text
                        rounded
                        icon="far fa-comment-question"
                        size="small"
                        class="font-semibold leading-tight rounded-full text-primary-500"
                        :aria-controls="showSupportForm ? 'support-dialog' : undefined"
                        :aria-expanded="showSupportForm"
                        @click="toggleSupportForm"
                    />
                    <Button
                        v-if="isSuperUser"
                        icon="far fa-video-plus"
                        :pt="{ icon: { class: ['!mr-0'] } }"
                        label="Add video"
                        rounded
                        size="small"
                        class="font-semibold leading-tight rounded-full"
                        @click="helpCenterStore.openVideoFormDialog()"
                    />
                </div>
            </div>
        </div>
        <Dialog
            ref="supportDialog"
            v-model:visible="showSupportForm"
            :class="{ 'w-full max-w-[700px] mx-10': !supportDialog?.maximized }"
            modal
            maximizable
            role="dialog"
            aria-modal="true"
            @hide="closeSupportForm"
        >
            <template #header>
                <h2 class="text-xl font-semibold">Submit a support request</h2>
            </template>
            <SupportRequestForm
                :is-submitting="isSubmitting"
                :template-type="templateType"
                @submit="handleSupportSubmit"
                @close="closeSupportForm"
            />
        </Dialog>
    </header>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
import { useHelpCenterStore } from '@/stores/helpCenter'
import { storeToRefs } from 'pinia'
import SupportRequestForm from './SupportRequestForm.vue'
import { useToast } from 'primevue/usetoast'

const helpCenterStore = useHelpCenterStore()
const userStore = useUserStore()
const { isSuperUser } = storeToRefs(userStore)
const toast = useToast()

const route = useRoute()
const router = useRouter()
const supportDialog = ref()
const showSupportForm = ref(false)
const isSubmitting = ref(false)
const templateType = ref<string | null>(null)

const toggleSupportForm = () => {
    if (showSupportForm.value) {
        // If we're closing the form, clear the template
        templateType.value = null
    }
    showSupportForm.value = !showSupportForm.value
}

const closeSupportForm = () => {
    showSupportForm.value = false
    templateType.value = null
}

const handleSupportSubmit = async (data: { emails: string[]; subject: string; description: string }) => {
    if (isSubmitting.value) return

    try {
        isSubmitting.value = true
        await helpCenterStore.submitSupportRequest(data)
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Your support request has been submitted successfully.',
            life: 3000
        })
        closeSupportForm()
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to submit support request. Please try again.',
            life: 5000
        })
    } finally {
        isSubmitting.value = false
    }
}

const isVideoLibraryActive = computed(() => route.path.includes('/video-library'))
const companyId = computed(() => route.params.companyId as string)

onMounted(() => {
    const { openSupportForm, type } = route.query

    // If we have query params we want to clean up, replace the route
    if (openSupportForm && type) {
        const newQuery = { ...route.query }
        delete newQuery.openSupportForm
        delete newQuery.type
        router.replace({ query: newQuery })
    }

    if (type && typeof type === 'string') {
        templateType.value = type
    }

    if (openSupportForm === 'true') {
        showSupportForm.value = true
    }
})
</script>

<style scoped>
:deep(.p-dialog-mask) {
    @apply backdrop-blur-sm bg-surface-800/30;
}
</style>
