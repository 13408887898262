import { createRouter, createWebHistory, type Router } from 'vue-router'
import TagsList from './components/TagsList.vue'

export const tagsRoutes = [
    {
        path: '/brand/:brandId/tags',
        name: 'TagsList',
        component: TagsList,
        meta: { requiresAuth: true }
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'TagsView') {
    router = createRouter({
        history: createWebHistory(),
        routes: tagsRoutes
    })
}
