<template>
    <div class="p-12 bg-white max-w-[1280px] mx-auto">
        <div class="py-6 flex justify-between mb-2">
            <h2 class="text-2xl font-bold text-center text-surface-700">Browse videos</h2>
            <Button label="Browse all" text class="text-sm font-medium" icon="far fa-arrow-right" icon-pos="right" />
        </div>

        <div class="flex justify-between flex-col space-y-8 sm:space-y-0 sm:flex-row sm:space-x-8">
            <div
                v-for="(category, key) in categories"
                :key="key"
                :class="['flex flex-col', category.filterKey === 'feature' ? 'w-full md:w-1/2' : 'w-full md:w-1/4']"
            >
                <div class="border-b border-black/10">
                    <h3 class="text-xs font-medium py-2.5 px-4 text-surface-500 uppercase">
                        {{ category.title }}
                    </h3>
                </div>
                <div :class="category.filterKey === 'feature' ? 'md:flex md:flex-wrap' : ''">
                    <ul
                        v-for="(column, colIndex) in getColumns(category.items)"
                        :key="colIndex"
                        class="space-y-2 mt-2"
                        :class="category.filterKey === 'feature' ? 'md:w-1/2' : 'md:w-full'"
                    >
                        <li v-for="(item, index) in column" :key="index" class="px-4 py-2">
                            <a
                                class="cursor-pointer hover:text-blue-600 transition"
                                @click="navigateToLibrary({ [category.filterKey]: item.value })"
                            >
                                <FaIcon v-if="item.icon" :icon="item.icon" class="mr-3" />
                                {{ item.label }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'BrowseVideos',
    setup() {
        const router = useRouter()

        const categories = {
            type: {
                title: 'By Type',
                filterKey: 'type',
                items: [
                    { label: 'Events', value: 'events', icon: 'far fa-presentation-screen' },
                    { label: 'Tuesday tutorials', value: 'tuesday_tutorials', icon: 'far fa-screen-users' },
                    { label: 'Training videos', value: 'training_videos', icon: 'far fa-graduation-cap' }
                ]
            },
            channel: {
                title: 'By Channel',
                filterKey: 'channel',
                items: [
                    { label: 'Calls', value: 'calls', icon: 'far fa-phone' },
                    { label: 'Documents', value: 'documents', icon: 'far fa-file-lines' },
                    { label: 'Email', value: 'email', icon: 'far fa-envelope' },
                    { label: 'Messaging', value: 'messaging', icon: 'far fa-messages' },
                    { label: 'Social', value: 'social', icon: 'far fa-thumbs-up' },
                    { label: 'Web', value: 'web', icon: 'far fa-laptop' }
                ]
            },
            feature: {
                title: 'By Feature',
                filterKey: 'feature',
                items: [
                    { label: 'Assets', value: 'assets', icon: null },
                    { label: 'Campaign', value: 'campaign', icon: null },
                    { label: 'Remediation', value: 'remediation', icon: null },
                    { label: 'Reporting', value: 'reporting', icon: null },
                    { label: 'Rules', value: 'rules', icon: null },
                    { label: 'Traffic sources', value: 'traffic_sources', icon: null },
                    { label: 'User management', value: 'user_management', icon: null },
                    { label: 'Vendor portal', value: 'vendor_portal', icon: null },
                    { label: 'Other', value: 'other', icon: null }
                ]
            }
        }

        const navigateToLibrary = (filter: Record<string, string>) => {
            router.push({
                name: 'VideoLibrary',
                query: filter
            })
        }

        const getColumns = (items: any[]) => {
            const columns = []
            for (let i = 0; i < items.length; i += 6) {
                columns.push(items.slice(i, i + 6))
            }
            return columns
        }

        return {
            categories,
            navigateToLibrary,
            getColumns
        }
    }
})
</script>
