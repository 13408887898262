import { watch, onMounted, type Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

interface RouteValidationOptions<T> {
    decodeQueryToState: (query: Record<string, string>) => void
    constructExpectedQuery: () => Record<string, string>
    onValidRoute: () => void
    filters: Ref<T>
}

export function useRouteValidation<T>({
    decodeQueryToState,
    constructExpectedQuery,
    onValidRoute,
    filters
}: RouteValidationOptions<T>) {
    const route = useRoute()
    const router = useRouter()

    let isProgrammaticRouteChange = false
    let isInitialLoad = true

    onMounted(() => {
        const query = { ...route.query }
        decodeQueryToState(query as Record<string, string>)
        const expectedQuery = constructExpectedQuery()

        if (JSON.stringify(query) !== JSON.stringify(expectedQuery)) {
            isProgrammaticRouteChange = true
            router.replace({ query: expectedQuery })
        }

        onValidRoute()

        isInitialLoad = false
    })

    watch(
        () => route.query,
        (newQuery) => {
            if (isProgrammaticRouteChange) {
                isProgrammaticRouteChange = false
                return
            }
            decodeQueryToState(newQuery as Record<string, string>)
            const expectedQuery = constructExpectedQuery()
            if (JSON.stringify(newQuery) !== JSON.stringify(expectedQuery)) {
                isProgrammaticRouteChange = true
                router.replace({ query: expectedQuery })
            } else {
                onValidRoute()
            }
        }
    )

    watch(
        filters,
        () => {
            if (isInitialLoad || isProgrammaticRouteChange) {
                return
            }
            const expectedQuery = constructExpectedQuery()
            if (JSON.stringify(route.query) !== JSON.stringify(expectedQuery)) {
                router.push({ query: expectedQuery })
            }
        },
        { deep: true }
    )
}
