import type { PageInfo } from "./PageInfo"

export enum DefaultScoreRange {
    Good = 'good',
    Fair = 'fair',
    Poor = 'poor'
}

export type ScoreRange =
    DefaultScoreRange.Good | DefaultScoreRange.Fair | DefaultScoreRange.Poor | null


export interface FilterAverageScorePayload extends TableFilter {
    minScore: number | null
    maxScore: number | null
    isCustom: boolean
}

export interface TableFilter {
    type: string
}

export enum OperandOptions {
    EqualTo = 'equalsTo',
    GreaterThan = 'greaterThan',
    LessThan = 'lessThan',
    GreaterThanEqual = 'greaterThanEqual',
    LessThanEqual = 'lessThanEqual',
    Between = 'between'
}

export interface OperandToIcon {
    equalsTo: string
    greaterThan: string
    lessThan: string
    greaterThanEqual: string
    lessThanEqual: string
    between: string
}

export type OperandType =
    OperandOptions.EqualTo
    | OperandOptions.GreaterThan
    | OperandOptions.LessThan
    | OperandOptions.GreaterThanEqual
    | OperandOptions.LessThanEqual
    | OperandOptions.Between

export const mapOperandToIcon: OperandToIcon = {
    equalsTo: 'fa-equals',
    greaterThan: 'fa-greater-than',
    lessThan: 'fa-less-than',
    greaterThanEqual: 'fa-greater-than-equal',
    lessThanEqual: 'fa-less-than-equal',
    between: 'fa-arrows-left-right'
}

export interface FilterUserRolePayload {
    client: number
    clientadmin: number
    staff: number
    submitter: number
    superuser: number  
    thirdparty: number
    vendor: number
}

export interface TableState<T extends string> {
    page: number;
    size: number;
    orderBy?: T;
    sortOrder: number | undefined;
    first?: number;
    isLoading?: boolean;
}

export interface ServiceResponse<T> {
    $schema: string
    data: T
    message?: string
    pageInfo?: PageInfo
}
