import axios from 'axios'
import { getCookie } from '../utils/cookies'

const httpClient = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL ?? `https://gateway.${window.location.hostname}`,
    timeout: 60000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
})

httpClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')
        const csrfToken = getCookie('csrftoken')
        const sessionId = getCookie('sessionid')
        const plineSession = getCookie('PerformLineSession')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken
        }
        if (sessionId) {
            config.headers['Cookie'] = `sessionid=${sessionId};`
        }

        if (plineSession) {
            config.headers['Cookie'] += `PerformLineSession=${plineSession}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

httpClient.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        console.error(error)
        if (error.response && error.response.status === 401) {
            // Handle unauthorized errors
            console.log('UNAUTHORIZED ERROR')
            // redirect to login, only if not already on login page
            if (!window.location.pathname.includes('/login') && !window.location.pathname.includes('/vendor/signup')) {
                window.location.href = '/login'
            }
        }
        return Promise.reject(error)
    }
)

export default httpClient
