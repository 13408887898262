import type { HelpCenterChannel } from '@/interfaces/HelpCenter'
import httpClient from './httpClient'
import { handleApiError } from '@/utils/handleApiError'
import type { AxiosResponse, AxiosError } from 'axios'
// import { masqueradeRoleData, sessionMe } from './mock'
export interface LoginResponse {
    success: boolean
    message: string
    data: any
}

export interface UserDataResponse {
    $schema: string
    data: UserData
    message?: string
}

export interface UserData {
    company: string
    email: string
    firstName: string
    lastName: string
    groupClaims: Record<string, any>
    isActive: boolean
    ssoEnabled?: boolean
    primaryGroupId: string
    roleGroupIds: Record<string, string[]>
    username: string
    activeRole: string
    lastActiveBrandId: string
    lastActiveBrandName: string
    lastActiveCompanyId: string
    lastActiveCompanyName: string
    companies: UserCompanyData[]
}

export interface UserCompanyData {
    id: string
    name: string
    roles: string[]
    claims: string[]
}

export interface UserBrandsResponse {
    $schema: string
    data: UserBrandData[]
    message?: string
}

export interface UserBrandData {
    channel: HelpCenterChannel
    id: string
    name: string
    roles?: string[]
    claims?: string[]
}

// Legacy - Accounts Service
export interface AccountsResponse<T> {
    Status: string
    StatusCode: number
    ServiceName: string
    ResultCount?: ResultCount
    Results: T
}
export interface UserSession {
    username: string
    original_username?: string
    capabilities?: string[]
    primary_group_id?: string 
    group_ids?: string[] 
    group_role_ids?: any
    nonce?: string 
    legacy_company_id?: number
}

export interface ResultCount {
    Total: number
    Current: number
    Limit: number
    Offset: number
}

export interface MasqueradeRoleResponse {
    current_user: string
    masquerade_users: MasqueradeUser[]
}

export interface MasqueradeUser {
    admin_user: string
    masquerade_user: string
}

export interface FeatureFlags {
    ux_tags_v2_show: boolean
    ux_team_directory: boolean
    ux_help_center: boolean
    ux_hide_legacy_bootstrap_css: boolean
    email_compliance_alert: boolean
    social_compliance_alert: boolean    
    bulk_pdf_button: boolean
    ux_account_page: boolean
}

export interface UpdateUserNameRequest {
    firstName: string
    lastName: string
}

export interface ChangePasswordRequest {
    current_password: string
    new_password: string
}

const login = async (username: string, password: string): Promise<LoginResponse> => {
    try {
        const response = await httpClient.post(
            '/auth/login',
            { username, password },
            {
                validateStatus: (status) => status >= 200 && status <= 303,
                withCredentials: true
            }
        )
        return { success: true, message: 'Login successful', data: response.data }
    } catch (error) {
        console.log('login error')
        throw handleApiError(error as AxiosError)
    }
}

const logout = async (): Promise<void> => {
    try {
        window.location.href = `${baseUrl}/accounts/v1/logout/?next=/login&errnext=/login`
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const forgotPassword = async (email: string): Promise<void> => {
    try {
        await httpClient.post('/auth/forgot-password', { username: email })
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const fetchUserData = async (): Promise<UserDataResponse> => {
    try {
        const response: AxiosResponse<UserDataResponse> = await httpClient.get<UserDataResponse>('/users/me')
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const fetchUserBrandsData = async (companyId: string): Promise<UserBrandsResponse> => {
    try {
        const response: AxiosResponse<UserBrandsResponse> = await httpClient.get<UserBrandsResponse>(
            `/users/me/brands?companyId=${companyId}`
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const resetPassword = async (newPassword: string, token: string): Promise<void> => {
    try {
        await httpClient.post('/auth/reset-password', { newPassword, resetToken: token })
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const baseUrl = import.meta.env.VITE_ROOT_URL ?? `https://${window.location.hostname}`

const fetchRootUserData = async (): Promise<UserSession> => {
    try {
        // if (mockApi) {
        //     return sessionMe.Results
        // }
        const resp = await httpClient.get<AccountsResponse<UserSession>>(`${baseUrl}/accounts/v1/me/session/`)
        return resp.data.Results
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const fetchMasqueradeRoleData = async (rootUser: string): Promise<MasqueradeRoleResponse> => {
    try {
        // if (mockApi) {
        //     return masqueradeRoleData.Results
        // }
        const resp = await httpClient.get<AccountsResponse<MasqueradeRoleResponse>>(
            `${baseUrl}/accounts/v1/masquerade/?q=admin_user/${rootUser}`
        )
        return resp.data.Results
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const setMasqueradeUser = async (username: string): Promise<any> => {
    try {
        const resp = await httpClient.post<any>(`${baseUrl}/accounts/v1/masquerade/${username}/`)
        return resp.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const getFeatureFlags = async (): Promise<FeatureFlags> => {
    try {
        const resp = await httpClient.get<any>(`${baseUrl}/util/feature_flags`)
        return resp.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const updateUserName = async (username: string, data: UpdateUserNameRequest): Promise<void> => {
    await httpClient.put(`/users/${username}/name`, data)
}

const changePassword = async (currentPassword: string, newPassword: string): Promise<void> => {
    try {
        await httpClient.post('/auth/change-password', { 
            current_password: currentPassword,
            new_password: newPassword 
        })
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

export default {
    login,
    logout,
    forgotPassword,
    fetchUserData,
    fetchUserBrandsData,
    resetPassword,
    fetchRootUserData,
    fetchMasqueradeRoleData,
    setMasqueradeUser,
    getFeatureFlags,
    updateUserName,
    changePassword,
}
