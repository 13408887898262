<template>
    <TableFilter
        :name="'avg-score'"
        :label="'Avg. score'"
        :is-active="isFilterActive"
        :enable-clear-filter="true"
        @clear-filter="clearScoreFilter"
    >
        <template #icon="{ variant }">
            <FaIcon icon="far fa-gauge-high" :class="variant"></FaIcon>
        </template>

        <template #value>
            <div v-if="isFilterActive" class="text-sm" data-testid="score-display">
                <div v-if="filterState.isCustom" class="flex items-center">
                    <span>{{ filterState.minScore || 0 }}%</span>
                    <FaIcon class="mx-0.5" icon="far fa-arrows-left-right" />
                    <span>{{ filterState.maxScore || 100 }}%</span>
                </div>
                <div v-else-if="isRangeGood" class="flex items-center">
                    <FaIcon class="mr-0.5" icon="far fa-greater-than-equal" />
                    <span>90%</span>
                </div>
                <div v-else-if="isRangeFair" class="flex items-center">
                    <span>75%</span>
                    <FaIcon class="mx-0.5" icon="far fa-arrows-left-right" />
                    <span>89%</span>
                </div>
                <div v-else-if="isRangePoor" class="flex items-center">
                    <FaIcon class="mr-0.5" icon="far fa-less-than-equal" />
                    <span>74%</span>
                </div>
            </div>
        </template>

        <template #content>
            <!-- Start Score Picker -->
            <div class="flex flex-col my-4 gap-2">
                <div class="flex w-full items-center mb-2">
                    <InputNumber
                        v-if="customFilterType == 'between'"
                        v-model="filterState.minScore"
                        data-testid="custom-score-input"
                        placeholder="%"
                        number
                        :min="0"
                        :max="100"
                        suffix="%"
                        :pt="{ pcInput: { root: { class: ['w-full'] } } }"
                        class="border-surface-950/20 h-9 self-end focus:outline-none focus:ring !focus:ring-brand-300 placeholder:text-surface-500 placeholder:text-sm"
                        @input="handleCustomScoreChange($event, 'min')"
                    />
                    <Button
                        v-tooltip.top="'Set custom range (inclusive)'"
                        data-testid="custom-score-button"
                        text
                        rounded
                        :class="{ 'mx-2': customFilterType == 'between' }"
                        class="!bg-brand-500/[.12] h-8 w-8 mr-2 pointer-events-none"
                        @click="toggleOperandPopover"
                    >
                        <FaIcon :icon="`far ${activeOperand}`" class="text-xs text-brand-500"></FaIcon>
                    </Button>
                    <InputNumber
                        v-model="filterState.maxScore"
                        data-testid="custom-score-input"
                        placeholder="%"
                        number
                        :min="0"
                        :max="100"
                        suffix="%"
                        :pt="{ pcInput: { root: { class: ['w-full'] } } }"
                        class="border-surface-950/20 h-9 self-end focus:outline-none focus:ring !focus:ring-brand-300 placeholder:text-surface-500 placeholder:text-sm"
                        @input="handleCustomScoreChange($event, 'max')"
                    />
                </div>
                <div
                    v-for="button in scoreRangeRadioButtons"
                    :key="button.inputId"
                    class="w-full content-center h-10 rounded-sm flex items-center hover:bg-brand-500/[.12]"
                    :class="{ 'bg-brand-500/[.12]': defaultRange == button.range }"
                >
                    <RadioButton
                        v-model="defaultRange"
                        :input-id="button.inputId"
                        name="avgScoreRange"
                        :data-testid="button.range + '-radio-btn'"
                        :value="button.range"
                        :pt="avgScoreFilterRadioPt"
                        @change="checkAvgScoreRange"
                    />
                    <label
                        class="text-sm text-surface-800 align-middle cursor-pointer w-full flex items-center h-full pr-4"
                        :for="button.inputId"
                    >
                        <FaIcon
                            icon="fas fa-circle"
                            class="rounded-full border border-surface-0 w-2 h-2 mr-1 ml-2 align-middle"
                            :class="button.circleIconClass"
                        >
                        </FaIcon>
                        {{ button.label[0] }}<FaIcon :icon="'far ' + button.operandIconClass"></FaIcon
                        >{{ button.label[1] }}
                    </label>
                </div>
            </div>
            <!-- End Score Picker -->

            <!-- Start Operand Selector
        <Popover ref="operandPopover" id="operand" key="operand"
            class="bg-surface-100 rounded-2xl shadow !border border-surface-950/10 after:content-none" :pt="{
                root: { class: ['w-60'] },
                content: { class: ['flex flex-wrap text-sm text-surface-700 px-0 py-2'] },
            }">
            <div @click="onOperandChange(OperandOptions.EqualTo)"
                class="flex items-center w-full flex-nowrap h-9 px-4 hover:text-brand-500 hover:bg-brand-500/[.12] hover:cursor-pointer">
                <FaIcon icon="far fa-equals"></FaIcon>
                <span class="ml-4">Equals to</span>
            </div>
            <div @click="onOperandChange(OperandOptions.GreaterThan)"
                class="flex items-center w-full flex-nowrap h-9 px-4 hover:text-brand-500 hover:bg-brand-500/[.12] hover:cursor-pointer">
                <FaIcon icon="far fa-greater-than"></FaIcon>
                <span class="ml-4">Greater than</span>
            </div>
            <div @click="onOperandChange(OperandOptions.LessThan)"
                class="flex items-center w-full flex-nowrap h-9 px-4 hover:text-brand-500 hover:bg-brand-500/[.12] hover:cursor-pointer">
                <FaIcon icon="far fa-less-than"></FaIcon>
                <span class="ml-4">Less than</span>
            </div>
            <div @click="onOperandChange(OperandOptions.GreaterThanEqual)"
                class="flex items-center w-full flex-nowrap h-9 px-4 hover:text-brand-500 hover:bg-brand-500/[.12] hover:cursor-pointer">
                <FaIcon icon="far fa-greater-than-equal"></FaIcon>
                <span class="ml-4">Greater than or equal to</span>
            </div>
            <div @click="onOperandChange(OperandOptions.LessThanEqual)"
                class="flex items-center w-full flex-nowrap h-9 px-4 hover:text-brand-500 hover:bg-brand-500/[.12] hover:cursor-pointer">
                <FaIcon icon="far fa-less-than-equal"></FaIcon>
                <span class="ml-4">Less than or equal to</span>
            </div>
            <div @click="onOperandChange(OperandOptions.Between)"
                class="flex items-center w-full flex-nowrap h-9 px-4 hover:text-brand-500 hover:bg-brand-500/[.12] hover:cursor-pointer">
                <FaIcon icon="far fa-arrows-left-right-to-line"></FaIcon>
                <span class="ml-4">Between</span>
            </div>
        </Popover>
    End Operand Selector-->
        </template>
        <template #footer>
            <TableFilterFooter :info-text="'The thresholds are based on system default.'"></TableFilterFooter>
        </template>
    </TableFilter>
</template>

<script lang="ts" setup>
import RadioButton from 'primevue/radiobutton'
import type { InputNumberInputEvent } from 'primevue/inputnumber'
import { useDebounceFn } from '@vueuse/core'
import { computed, ref, watch, type PropType } from 'vue'
import {
    DefaultScoreRange,
    mapOperandToIcon,
    OperandOptions,
    type FilterAverageScorePayload,
    type OperandType,
    type ScoreRange
} from '@/interfaces/Table'
import TableFilterFooter from '@/components/dataTable/tableFilter/TableFilterFooter.vue'
import TableFilter from '@/components/dataTable/tableFilter/TableFilter.vue'

const props = defineProps({
    avgScoreFilterPayload: {
        type: Object as PropType<FilterAverageScorePayload>,
        required: true
    }
})

const emit = defineEmits(['update-avg-score-filter', 'remove-filter'])

const customFilterType = ref<OperandType>(OperandOptions.Between)
const defaultRange = ref<ScoreRange>(null)

const filterState = ref<FilterAverageScorePayload>({
    minScore: null,
    maxScore: null,
    type: 'avgScore',
    isCustom: false
})

const scoreRangeRadioButtons = [
    {
        range: DefaultScoreRange.Good,
        inputId: 'good',
        circleIconClass: 'text-green-500 dark:text-green-800',
        label: ['Good (', '90%)'],
        operandIconClass: 'fa-greater-than-equal'
    },
    {
        range: DefaultScoreRange.Fair,
        inputId: 'fair',
        circleIconClass: 'text-orange-500 dark:text-orange-200',
        label: ['Fair (75%', '89%)'],
        operandIconClass: 'fa-arrows-left-right'
    },
    {
        range: DefaultScoreRange.Poor,
        inputId: 'poor',
        circleIconClass: 'text-red-500 dark:text-red-200',
        label: ['Poor (74%', ')'],
        operandIconClass: 'fa-less-than-equal'
    }
]

watch(
    () => props.avgScoreFilterPayload,
    (payload: FilterAverageScorePayload) => {
        filterState.value = payload
        if (payload.minScore !== null && payload.maxScore !== null && payload.isCustom === false) {
            setSelectedDefaultRange(payload.maxScore)
        } else if (payload.minScore === null && payload.maxScore === null && payload.isCustom === false) {
            // When ClearAll is pressed
            defaultRange.value = null
        }
    }
)

watch(
    () => filterState.value.isCustom,
    (newIsCustom, oldIsCustom) => {
        if (oldIsCustom === false && newIsCustom === true) {
            defaultRange.value = null
        }
    }
)

const isRangeGood = computed(() => {
    return filterState.value.maxScore === 100 && filterState.value.minScore === 90
})

const isRangeFair = computed(() => {
    return filterState.value.maxScore === 89 && filterState.value.minScore === 75
})

const isRangePoor = computed(() => {
    return filterState.value.maxScore === 74 && filterState.value.minScore === 0
})

const isFilterActive = computed(() => {
    return filterState.value.minScore !== null && filterState.value.maxScore !== null ? true : false
})

const activeOperand = computed(() => {
    return mapOperandToIcon[customFilterType.value]
})

const operandPopover = ref()
const toggleOperandPopover = (event: Event) => {
    operandPopover.value.toggle(event)
}

// const onOperandChange = (operand: OperandType) => {
//     customFilterType.value = operand
//     if (operand != OperandOptions.Between) {
//         debouncedAvgScore(avgScore.value, 'default')
//     }
// }

const debouncedAvgScore = useDebounceFn(
    () => {
        const minScore = filterState.value.minScore !== null ? filterState.value.minScore : 0
        const maxScore = filterState.value.maxScore !== null ? filterState.value.maxScore : 100

        emit('update-avg-score-filter', {
            type: 'avgScore',
            minScore,
            maxScore,
            isCustom: true
        })
    },
    600,
    { maxWait: 3000 }
)

const handleCustomScoreChange = (event: InputNumberInputEvent, inputType: 'min' | 'max') => {
    let val = event.value !== null ? parseInt(event.value as string) : null

    if (inputType === 'min' && val !== null) val = Math.max(0, val)
    if (inputType === 'max' && val !== null) val = Math.min(100, val)

    filterState.value[inputType === 'min' ? 'minScore' : 'maxScore'] = val

    if(filterState.value.minScore !== null && filterState.value.maxScore !== null) debouncedAvgScore()
}

const checkAvgScoreRange = () => {
    const scoreFilter: FilterAverageScorePayload = {
        type: 'avgScore',
        minScore: 0,
        maxScore: 100,
        isCustom: false
    }
    if (defaultRange.value == DefaultScoreRange.Good) {
        scoreFilter.minScore = 90
        scoreFilter.maxScore = 100
    } else if (defaultRange.value == DefaultScoreRange.Fair) {
        scoreFilter.minScore = 75
        scoreFilter.maxScore = 89
    } else if (defaultRange.value == DefaultScoreRange.Poor) {
        scoreFilter.minScore = 0
        scoreFilter.maxScore = 74
    }

    if (scoreFilter.minScore && scoreFilter.minScore < 0) {
        scoreFilter.minScore = 0
    }
    if (scoreFilter.maxScore && scoreFilter.maxScore > 100) {
        scoreFilter.maxScore = 100
    }
    if (scoreFilter.maxScore) {
        setSelectedDefaultRange(scoreFilter.maxScore)
    }

    // TODO: Remove this setTimeout and fix lag on change
    setTimeout(() => {
        emit('update-avg-score-filter', scoreFilter)
    }, 100)
}

const clearScoreFilter = () => {
    defaultRange.value = null
    filterState.value = {
        minScore: null,
        maxScore: null,
        type: 'avgScore',
        isCustom: false
    }
    emit('remove-filter', 'avgScore')
}

function setSelectedDefaultRange(maxScore: number) {
    if (maxScore <= 74) {
        defaultRange.value = DefaultScoreRange.Poor
        filterState.value.minScore = 0
        filterState.value.maxScore = 74
    } else if (maxScore < 90 && maxScore >= 75) {
        defaultRange.value = DefaultScoreRange.Fair
        filterState.value.minScore = 75
        filterState.value.maxScore = 89
    } else if (maxScore <= 100 && maxScore >= 90) {
        defaultRange.value = DefaultScoreRange.Good
        filterState.value.minScore = 90
        filterState.value.maxScore = 100
    } else {
        // For now, default to Good Score range
        defaultRange.value = DefaultScoreRange.Good
        filterState.value.minScore = 90
        filterState.value.maxScore = 100
    }
}

const avgScoreFilterRadioPt = {
    root: { class: ['mr-2 ml-4'] },
    icon: { class: ['!hover:border-surface-800 focus:border-surface-800'] },
    box: ({ props }: any) => ({
        class: [
            {
                '!border-surface-800': true,
                'peer-hover:border-surface-800 dark:peer-hover:border-surface-800': !props.disabled && !props.invalid,
                'hover:border-surface-800 focus:border-surface-800': true,
                'border-surface-800': props.value == props.modelValue && props.value !== undefined,
                'bg-surface-800': props.value == props.modelValue && props.value !== undefined
            }
        ]
    })
}
</script>
